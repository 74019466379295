import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme from '@mui/material/styles/createTheme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets/scss/app.scss';
import AppRoutes from 'routes';
import { theme } from '../../theme';
import { useApp } from './app-hooks';
import { appStyles as styles } from './styles';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import NewRelicInitializer from './newrelic';
import SiteStatusPage from 'pages/site-status-page/site-status-page';

const App = () => {
  const {
    customerDetailLoading,
    isAppLoading,
    setIsAppLoading,
    customerDetails,
    isVerifyTokenLoading,
    isCheckTokenLoading,
    queryClient,
  } = useApp();

  if (document.readyState === 'complete' && isAppLoading) {
    setIsAppLoading(false);
  } else if (document.readyState === 'complete') {
    window.addEventListener('load', () => {
      setIsAppLoading(false);
      window.removeEventListener('load');
    });
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <NewRelicInitializer />
        {isAppLoading ||
        customerDetailLoading ||
        isVerifyTokenLoading ||
        isCheckTokenLoading ||
        !customerDetails ? (
          <Box sx={styles.loaderContainer}>
            <CircularProgress />
          </Box>
        ) : customerDetails?.is_maintenance_mode ? (
          <SiteStatusPage maintenanceMode={true} />
        ) : (
          <ThemeProvider
            theme={createTheme(theme, {
              palette: {
                primary: {
                  main: customerDetails?.primary_color
                    ? customerDetails.primary_color
                    : '#f02711',
                  light: customerDetails?.primary_color
                    ? customerDetails?.primary_color
                    : '#f02711',
                },
                secondary: {
                  main: '#cdccd6',
                  contrastText: '#4D4663',
                },
              },
            })}
          >
            {/* <NewRelicInitializer /> */}
            <AppRoutes />
          </ThemeProvider>
        )}
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
