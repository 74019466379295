import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import { newAccountFormSchema } from './validation-schema';
import Steps from './steps';
import { useNewAccountForm } from './new-account-form-hooks';
import NewAccountSkeleton from './new-account-skeleton';
import FormError from '../../components/form-error';
import { newAccountStyles as styles } from '../styles';
import Tnc from '../../../../components/tnc';

import { responsiveBreakpoints } from 'utils/utils';

const NewAccountRequestForm = ({ handleReviewMessage }) => {
  const {
    handleSubmitBtnClick,
    handlePreviousBtnClick,
    handleSameAddressChange,
    isSameAddress,
    activeStep,
    isLastStep,
    isNewRequestLoading,
    apiErrorMessage,
    fieldsError,
    getInitialValues,
    customerDetail,
    handleCloseError,
    isShowTnc,
    setIsShowTnc,
    tnc,
    handleTermsAndConditionClick,
    isTncLoading,
  } = useNewAccountForm(handleReviewMessage);

  const { theme, mobile, tablet } = responsiveBreakpoints();

  const { request_form_fields, media } = customerDetail;

  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid
        item
        sx={{
          width: '100%',
          padding: '5px 0px 5px 0px',
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Typography
            sx={
              mobile
                ? { ...styles.thankYouInterestMsg, width: '100%' }
                : styles.thankYouInterestMsg
            }
          >
            Thank you for your interest in working with us. Please fill in the
            information below and we will get back to you shortly.
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          padding: '5px 0px 10px 0px',
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
          spacing={2}
        >
          {apiErrorMessage || fieldsError.length > 0 ? (
            <FormError
              errorText={apiErrorMessage}
              errors={fieldsError}
              handleCloseError={handleCloseError}
            />
          ) : null}
          {customerDetail?.request_form_fields?.length > 1 ? (
            <Box sx={styles.stepsContainer}>
              {activeStep === 2 ? (
                <Box sx={styles.previousStepContainer}>
                  <Typography
                    sx={styles.previousStepText}
                    onClick={() => handlePreviousBtnClick()}
                  >
                    &lt; Previous Step
                  </Typography>
                  <Typography sx={styles.stepsText}>
                    | Step {activeStep}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={styles.stepsText}
                >{`Step ${activeStep}`}</Typography>
              )}
            </Box>
          ) : null}
        </Stack>
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          padding: '5px 0px 5px 0px',
        }}
      >
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          {request_form_fields ? (
            <Formik
              validateOnMount
              validationSchema={newAccountFormSchema(
                request_form_fields,
                activeStep
              )}
              initialValues={getInitialValues(request_form_fields)}
              onSubmit={handleSubmitBtnClick}
            >
              {({ handleSubmit, ...rest }) => (
                <form
                  onSubmit={handleSubmit}
                  autoComplete='off'
                  style={
                    mobile
                      ? {
                          width: '100%',
                        }
                      : {
                          width: '480px',
                        }
                  }
                >
                  <Steps
                    requestFormFields={request_form_fields}
                    rest={rest}
                    isSameAddress={isSameAddress}
                    handleSameAddressChange={handleSameAddressChange}
                    activeStep={activeStep}
                  />
                  <Typography sx={styles.readTncText}>
                    By clicking on Log in button, above, you are indicating that
                    you have read and agree with the{' '}
                    <Link
                      underline='none'
                      sx={styles.tncLink}
                      onClick={(e) => {
                        e.preventDefault();
                        handleTermsAndConditionClick();
                      }}
                    >
                      Terms &amp; Conditions
                    </Link>
                  </Typography>

                  <Button
                    type='submit'
                    variant='contained'
                    sx={styles.defaultWidth}
                    disabled={isNewRequestLoading}
                    endIcon={
                      isNewRequestLoading && <CircularProgress size={16} />
                    }
                  >
                    {isLastStep ? 'Submit Request' : 'Next Step'}
                  </Button>
                </form>
              )}
            </Formik>
          ) : (
            <NewAccountSkeleton />
          )}
        </Stack>
      </Grid>
      {isShowTnc && (
        <Tnc
          open={isShowTnc}
          setOpen={setIsShowTnc}
          tnc={tnc}
          isTncLoading={isTncLoading}
        />
      )}
    </Grid>
  );
};

NewAccountRequestForm.propTypes = {
  handleReviewMessage: PropTypes.func.isRequired,
};

export default NewAccountRequestForm;
