import Button from '@mui/material/Button';
import { CircularProgress, IconButton, Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Avatar from '@mui/material/Avatar';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import TextField from '../../text-field';
import MyModal from '../modal';
import PencilIcon from '../../../assets/icons/pencil-icon';
import TableSortIcon from '../../../assets/icons/table-sort-icon';
import SearchIconVariant from '../../../assets/icons/search-icon-variant';
import EditGroupDetailsModal from './edit-group-details/edit-group-details-modal';
import useCreateCustomerModal from './create-customer-group-modal-hooks';
import TablePaginationActions from '../../Pagination/table-pagination-actions';
import AlertModal from 'components/modals/alert/alert-modal';
import useRetailerBuyerCard from 'pages/customers/components/retailer-buyer-card/retailer-buyer-card-hooks';
import useFilterOptions from '../../../pages/customers/components/filter-options-hooks';
import FilterOptions from '../../../pages/customers/components/filter-options';
import Box from '@mui/material/Box';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EmptyState from 'components/empty-state';
import InfoIcon from '@mui/icons-material/Info';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';
import { camelCaseFirstLetters } from 'utils/helper';

const CreateCustomerGroupModal = ({
  open,
  setOpen,
  edit,
  setEdit,
  data,
  sendApiRequest,
}) => {
  const {
    rowsPerPage,
    handleChangeRowsPerPage,
    page,
    onPageChangeHandler,
    handleCheck,
    buyersData,
    selectedBuyers,
    setSelectedBuyers,
    showEditGroupDetails,
    setShowEditGroupDetails,
    globalSearchValue,
    setGlobalSearchValue,
    groupNameValue,
    setGroupNameValue,
    groupDescriptionValue,
    setGroupDescriptionValue,
    showZeroBuyerError,
    setShowZeroBuyerError,
    showBuyers,
    setShowBuyers,
    columnSearchValues,
    setColumnSearchValues,
    salesRep,
    setSalesRep,
    priceGroup,
    setPriceGroup,
    currency,
    setCurrency,
    filterString,
    applyFilters,
    isSelectAll,
    setIsSelectAll,
    handleClose,
    getBuyerByDebounce,
    isTableDataLoading,
    setIsTableDataLoading,
    sortColumnValue,
    setSortColumnValue,
    createColumnJsonString,
    clearFilters,
  } = useCreateCustomerModal(data, edit, open, setOpen);

  const close = (
    <Button
      variant='outlined'
      color='secondary'
      onClick={(e) => {
        handleClose();
      }}
    >
      Close
    </Button>
  );

  const saveChanges = (
    <Button
      onClick={(e) => {
        e.preventDefault();
        if (selectedBuyers?.length === 0) {
          setShowZeroBuyerError(true);
        } else {
          sendApiRequest(
            groupNameValue,
            groupDescriptionValue,
            isSelectAll ? '' : selectedBuyers,
            isSelectAll,
            filterString
          );
          handleClose();
          edit = false;
          setGroupNameValue('Group Name ##');
          setGroupDescriptionValue('Your description here');
          setSelectedBuyers([]);
        }
      }}
      variant='contained'
    >
      Save Changes
    </Button>
  );
  const editIconButton = (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (edit) {
          setEdit(true);
        }

        setShowEditGroupDetails(true);
      }}
    >
      <PencilIcon />
    </IconButton>
  );
  return (
    <MyModal
      title={groupNameValue}
      editIconButton={editIconButton}
      subTitle={groupDescriptionValue}
      open={open}
      setOpen={(bool) => {
        if (!bool) handleClose();
        else setOpen(bool);
      }}
      size='fullWidth'
      showCloseIcon
      actions={[close, saveChanges]}
    >
      <EditGroupDetailsModal
        open={showEditGroupDetails}
        setOpen={setShowEditGroupDetails}
        onSubmithandler={(values) => {
          setGroupNameValue(values.groupName);
          setGroupDescriptionValue(values.groupDesc);
          if (edit) {
            setEdit(false);
          }
        }}
        data={{ name: groupNameValue, description: groupDescriptionValue }}
        edit={edit}
      />

      {/* alert to show when no buyers are selected */}
      <AlertModal
        open={showZeroBuyerError}
        setOpen={setShowZeroBuyerError}
        title='Please select at least one buyer'
        type='warning'
      />

      <Stack spacing={3} sx={{ padding: '18px 25px 18px 25px' }}>
        {/* tablecontainer */}
        <FilterOptions
          setSearchValue={(value) => {
            setGlobalSearchValue(value);
            let payload = {
              page,
              rowsPerPage,
              globalSearchValue: value,
              filterString,
              sortByColumn: sortColumnValue?.columnName,
              sortValue: sortColumnValue?.sortValue,
            };
            setIsTableDataLoading(true);
            getBuyerByDebounce(payload);
          }}
          searchValue={globalSearchValue}
          salesRep={salesRep}
          setSalesRep={setSalesRep}
          priceGroup={priceGroup}
          setPriceGroup={setPriceGroup}
          currency={currency}
          setCurrency={setCurrency}
          showBuyers={showBuyers}
          setShowBuyers={setShowBuyers}
          applyFilters={applyFilters}
          buyer={true}
          clearFilters={clearFilters}
        />
        <Box>
          <Stack
            direction='row'
            spacing={1}
            sx={{
              paddingLeft: '10px',
              alignItems: 'center',
              border: '1px solid #E3E7ED',
            }}
          >
            <Checkbox
              checked={
                isSelectAll ||
                (buyersData?.data?.length > 0
                  ? selectedBuyers.length === buyersData?.data?.length
                  : false)
              }
              onClick={(e) => {
                if (isSelectAll) {
                  setSelectedBuyers([]);
                } else {
                  buyersData?.data?.forEach((buyer) => {
                    setSelectedBuyers((prev) => {
                      return [...prev, buyer?.id];
                    });
                  });
                }
                setIsSelectAll(!isSelectAll);
              }}
            />
            <Typography>
              {isSelectAll
                ? ` All ${buyersData?.count} buyers are selected`
                : `(${selectedBuyers.length} Buyers Selected)`}
            </Typography>
            <Button
              variant='text'
              sx={{ color: '#008CD6' }}
              onClick={() => {
                if (isSelectAll) {
                  setSelectedBuyers([]);
                } else {
                  buyersData?.data?.forEach((buyer) => {
                    setSelectedBuyers((prev) => {
                      return [...prev, buyer?.id];
                    });
                  });
                }
                setIsSelectAll(!isSelectAll);
              }}
            >
              {isSelectAll ? `Undo` : ' Select All Buyers from the list'}
            </Button>
          </Stack>
          {buyersData ? (
            <Box>
              <TableContainer sx={{ width: '100%' }}>
                <Table
                  size='small'
                  sx={{
                    border: '1px solid var(--secondary-border-color)',
                    width: '100%',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {buyersData?.columns.map((column) => {
                        if (column?.enabled)
                          return (
                            <TableCell
                              sx={{
                                cursor: 'pointer',
                                minWidth: '140px',
                              }}
                              onClick={() => {
                                let tempSortValue = 'ASC';
                                if (
                                  sortColumnValue?.columnName ==
                                  column?.accessor
                                ) {
                                  if (sortColumnValue?.sortValue == 'ASC')
                                    tempSortValue = 'DESC';
                                }
                                let payload = {
                                  page,
                                  rowsPerPage,
                                  globalSearchValue,
                                  filterString,
                                  sortByColumn: column?.accessor,
                                  sortValue: tempSortValue,
                                };
                                setIsTableDataLoading(true);
                                setSortColumnValue({
                                  columnName: column?.accessor,
                                  sortValue: tempSortValue,
                                });
                                getBuyerByDebounce(payload);
                              }}
                            >
                              <Stack
                                direction='row'
                                spacing={1}
                                sx={{
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  variant='subtitle1'
                                  fontWeight={700}
                                  sx={{
                                    width: 'fit-content',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {column?.header}
                                </Typography>
                                <IconButton
                                // onClick={() =>
                                //   sortBuyersColumns(column?.accessor)
                                // }
                                >
                                  {sortColumnValue.columnName ===
                                  column?.accessor ? (
                                    sortColumnValue?.sortValue === 'DESC' ? (
                                      <ArrowDropUpIcon />
                                    ) : (
                                      <ArrowDropDownIcon />
                                    )
                                  ) : (
                                    <TableSortIcon />
                                  )}
                                </IconButton>
                              </Stack>
                            </TableCell>
                          );
                      })}
                    </TableRow>
                    <TableRow>
                      {buyersData?.columns.map((column) => {
                        if (column?.enabled)
                          return (
                            <TableCell
                              sx={{
                                backgroundColor:
                                  'var(--paper-background-color)',
                              }}
                            >
                              <TextField
                                placeholder='Search'
                                id={column?.accessor}
                                startAdornment={
                                  <SearchIconVariant
                                    sx={{ marginRight: '6px' }}
                                  />
                                }
                                value={(() => {
                                  var val = '';
                                  columnSearchValues.forEach((obj) => {
                                    if (obj.label === column?.accessor) {
                                      val = obj.value;
                                    }
                                  });
                                  return val;
                                })()}
                                onChange={(e) => {
                                  try {
                                    var searchValues = [];
                                    columnSearchValues.forEach((obj) => {
                                      if (obj.label !== column?.accessor)
                                        searchValues.push(obj);
                                    });
                                    searchValues.push({
                                      label: column?.accessor,
                                      value: e.target.value,
                                    });
                                    setColumnSearchValues(searchValues);
                                    createColumnJsonString(searchValues);
                                  } catch (err) {
                                    LoggingService.log(
                                      'onChange',
                                      'CreateCustomerGroupModal',
                                      err,
                                      LogLevel.ERROR,
                                      'This error logged from create-customer-group-modal.jsx file line no 521',
                                      {}
                                    );
                                  }
                                }}
                                sx={{
                                  marginBottom: 0,
                                  height: '28px',
                                  fontSize: '13px',
                                  paddingLeft: '10px',
                                  backgroundColor: (() => {
                                    let bgcolor = '';
                                    columnSearchValues.forEach((obj) => {
                                      if (
                                        obj?.label === `${column?.accessor}`
                                      ) {
                                        obj.value != ''
                                          ? (bgcolor = '#cfe2ff')
                                          : '';
                                      }
                                    });
                                    return bgcolor;
                                  })(),
                                }}
                              />
                            </TableCell>
                          );
                      })}
                    </TableRow>
                  </TableHead>

                  {isTableDataLoading ? (
                    <TableBody>
                      {[...Array(10)].map((e, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell
                              colSpan={
                                buyersData?.columns?.filter(
                                  (cols) => cols?.enabled
                                )?.length
                              }
                            >
                              <Box
                                sx={{ marginLeft: '10px', marginRight: '10px' }}
                              >
                                <Skeleton height='60px' />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <>
                        {buyersData &&
                          buyersData?.data?.map((row, i) => {
                            return (
                              <TableRow key={i}>
                                {buyersData?.columns?.map((column) => {
                                  if (column?.enabled) {
                                    if (column?.accessor == 'buyerName')
                                      return (
                                        <TableCell>
                                          <Stack
                                            direction='row'
                                            spacing={1}
                                            alignItems='center'
                                          >
                                            <Checkbox
                                              checked={(() => {
                                                var x = false;
                                                selectedBuyers?.forEach(
                                                  (item) => {
                                                    if (item === row.id)
                                                      x = true;
                                                  }
                                                );
                                                return x;
                                              })()}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                handleCheck(row);
                                              }}
                                            />
                                            {row?.profile_pic ? (
                                              <img
                                                src={row.profile_pic}
                                                style={{
                                                  width: '30px',
                                                  height: '30px',
                                                  objectFit: 'contain',
                                                }}
                                              />
                                            ) : (
                                              <Avatar
                                                size='small'
                                                sx={{
                                                  width: '30px',
                                                  height: '30px',
                                                  fontSize: '14px',
                                                }}
                                              >
                                                {camelCaseFirstLetters(
                                                  row?.buyerName
                                                )}
                                              </Avatar>
                                            )}
                                            <Typography
                                              sx={{
                                                color:
                                                  'var(--primary-font-color)',
                                                fontSize: '15px',
                                                fontWeight: '900',
                                              }}
                                            >
                                              {row.buyerName}
                                            </Typography>
                                          </Stack>
                                        </TableCell>
                                      );
                                    else
                                      return (
                                        <TableCell>
                                          {row[column?.accessor]}
                                        </TableCell>
                                      );
                                  }
                                })}
                              </TableRow>
                            );
                          })}
                      </>
                    </TableBody>
                  )}
                  <TableFooter>
                    {buyersData && buyersData.data.length === 0 ? (
                      <TableCell colspan='8'>
                        {' '}
                        <EmptyState
                          title='No records found'
                          subTitle='We couldnt find any Records. Try adjusting your filters to display results'
                          icon={
                            <InfoIcon
                              sx={{
                                fontSize: 60,
                                color: 'var(--primary-icon-color)',
                              }}
                            />
                          }
                          sx={{
                            border: '1px solid var(--secondary-border-color)',
                            height: '150px',
                          }}
                        />
                      </TableCell>
                    ) : null}
                  </TableFooter>
                </Table>
              </TableContainer>
              {/* tablecontainer ends */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'right',
                  border: '1px solid var(--secondary-border-color)',
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[
                    25, 50,
                    100 /*{ label: 'All', value: buyersData.count - 1 }*/,
                  ]}
                  count={buyersData.count}
                  onPageChange={onPageChangeHandler}
                  rowsPerPage={
                    buyersData?.count < 25 ? buyersData.count : rowsPerPage
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  ActionsComponent={TablePaginationActions}
                  sx={{ border: 0 }}
                />
              </Box>
            </Box>
          ) : (
            <>
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height='510px'
              />
              <Box
                width='100vw'
                height='100vh'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                  opacity: 0.4,
                  backgroundColor: '#000',
                }}
              >
                <CircularProgress />
              </Box>
            </>
          )}
        </Box>
      </Stack>
    </MyModal>
  );
};

CreateCustomerGroupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  data: PropTypes.object,
  sendApiRequest: PropTypes.func.isRequired,
  setEdit: PropTypes.func,
};
CreateCustomerGroupModal.defaultProps = {
  data: null,
  edit: false,
  setEdit: () => {},
};

export default CreateCustomerGroupModal;
