import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Checkbox, InputLabel } from '@mui/material';
import TextField from 'components/text-field';
import PropTypes from 'prop-types';
import { stepsStyle as styles } from '../styles';

const Steps = ({
  requestFormFields,
  rest,
  isSameAddress,
  handleSameAddressChange,
  activeStep,
}) => {
  const copyBillingAddress = (fields) => {
    if (!isSameAddress) {
      fields.map((field) => {
        let value = rest.values[`billing_${field.name}`];
        rest.setFieldValue(`shipping_${field.name}`, value);
      });
    } else {
      fields.map((field) => {
        rest.setFieldValue(`shipping_${field.name}`, '');
      });
    }
  };

  const handleBillingBlur = (fieldName, value) => {
    if (isSameAddress) {
      rest.setFieldValue(`shipping_${fieldName}`, value);
    }
  };

  return requestFormFields.length > 0
    ? requestFormFields.map((step) => (
        <Stack spacing={2} key={step.step} marginBottom='15px'>
          {step.step === activeStep &&
            step.fields.map((field) =>
              field?.is_address ? (
                <Box key={'billing-address'}>
                  <Typography sx={styles.sectionTitle}>
                    Billing Address
                  </Typography>
                  {field?.billing_address.map((addressField) => (
                    <TextField
                      required={addressField?.constraints?.required}
                      key={`billing_${addressField.name}`}
                      id={`billing_${addressField.name}`}
                      name={`billing_${addressField.name}`}
                      label={addressField.label}
                      placeholder={addressField.label}
                      value={rest.values[`billing_${addressField.name}`]}
                      onChange={(e) => {
                        rest.handleChange(e);
                        handleBillingBlur(addressField.name, e?.target?.value);
                      }}
                      error={
                        rest.touched[`billing_${addressField.name}`] &&
                        Boolean(rest.errors[`billing_${addressField.name}`])
                      }
                      helperText={
                        rest.touched[`billing_${addressField.name}`] &&
                        rest.errors[`billing_${addressField.name}`]
                      }
                      sx={styles.defaultWidth}
                      marginBottom='16px'
                    />
                  ))}
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={styles.shippingContainer}
                  >
                    <Typography sx={styles.sectionTitle}>
                      Shipping Address
                    </Typography>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Checkbox
                        sx={styles.sameAddCheckbox}
                        checked={isSameAddress}
                        onChange={() => {
                          handleSameAddressChange();
                          copyBillingAddress(field?.shipping_address);
                        }}
                      />
                      <InputLabel sx={styles.sectionTitle}>
                        Same as Billing Address
                      </InputLabel>
                    </Stack>
                  </Stack>

                  {field?.shipping_address.map((addressField) => (
                    <TextField
                      disabled={isSameAddress}
                      required={addressField?.constraints?.required}
                      key={`shipping_${addressField.name}`}
                      id={`shipping_${addressField.name}`}
                      name={`shipping_${addressField.name}`}
                      label={addressField.label}
                      placeholder={addressField.label}
                      value={rest.values[`shipping_${addressField.name}`]}
                      onChange={rest.handleChange}
                      error={
                        rest.touched[`shipping_${addressField.name}`] &&
                        Boolean(rest.errors[`shipping_${addressField.name}`])
                      }
                      helperText={
                        rest.touched[`shipping_${addressField.name}`] &&
                        rest.errors[`shipping_${addressField.name}`]
                      }
                      sx={styles.defaultWidth}
                      marginBottom='16px'
                    />
                  ))}
                </Box>
              ) : (
                <TextField
                  required={field?.constraints?.required}
                  key={field.name}
                  id={field.name}
                  name={field.name}
                  label={field.label}
                  placeholder={field.label}
                  value={rest.values[`${field.name}`]}
                  onChange={rest.handleChange}
                  error={
                    rest.touched[`${field.name}`] &&
                    Boolean(rest.errors[`${field.name}`])
                  }
                  helperText={
                    rest.touched[`${field.name}`] &&
                    rest.errors[`${field.name}`]
                  }
                  sx={styles.defaultWidth}
                />
              )
            )}
        </Stack>
      ))
    : null;
};

Steps.propTypes = {
  requestFormFields: PropTypes.array.isRequired,
  rest: PropTypes.any.isRequired,
  isSameAddress: PropTypes.bool.isRequired,
  handleSameAddressChange: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default Steps;
