import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

import { useDepartmentsBar } from './department-bar-hooks';
import { DepartmentStyles as styles } from './styles';
import DepartmentMegaMenu from './department-mega-menu/department-mega-menu';
import DepartmentDrawerMenu from './department-drawer/department-drawer-menu';
import { useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import SessionStorageManager from 'utils/session-storage-manager';
import { zIndex } from 'constants/zIndex';
import { welcomeBarDataSelector } from 'redux/selectors';
import isEmpty from 'lodash/isEmpty';
import { responsiveBreakpoints } from 'utils/utils';

const DepartmentsBar = () => {
  const {
    departments,
    departmentsLoading,
    handleOpenMegaMenu,
    handleOpenMoreMenu,
    handleCloseMoreMenu,
    openMegaMenu,
    openMoreMenu,
    selectedDepartment,
    customerDetail,
    handleShopByLookBookClick,
    handleDepartmentClick,
    menuButtonXPosition,
    departmentContainerWidth,
  } = useDepartmentsBar();
  const welcomeBarConfig = useSelector(welcomeBarDataSelector);
  const { mainWidth } = responsiveBreakpoints();
  const [overflowItems, setOverflowItems] = useState([]);

  useEffect(() => {
    const handleOverflow = () => {
      const boxElement = boxRef.current;
      if (boxElement) {
        const isOverflowing =
          boxElement.scrollWidth > boxElement.getBoundingClientRect().width;
        if (isOverflowing) {
          // If overflowing, update the list of overflow items
          const visibleItems = departments.filter((item) => {
            const itemElement = document.getElementById(item?.genderId);
            return (
              itemElement &&
              itemElement.offsetLeft + itemElement.offsetWidth <=
                boxElement.getBoundingClientRect().width
            );
          });
          const overflowedItems = departments.filter(
            (item) => !visibleItems.includes(item)
          );
          setOverflowItems(overflowedItems);
        }
      }
    };
    handleOverflow();
    // Listen for resize events to handle dynamic changes
    window.addEventListener('resize', handleOverflow);
    return () => {
      window.removeEventListener('resize', handleOverflow);
    };
  }, [departmentContainerWidth]);
  const [searchParams] = useSearchParams();
  let myRef = useRef();
  const boxRef = useRef(null);
  return (
    <Stack>
      <Box
        sx={{
          height: '40px',
          maxHeight: '50px',
          backgroundColor: 'var(--paper-background-color)',
          boxShadow: 0,
          borderTop: '1px solid rgba(204, 204, 204, 0.6)',
          borderBottom: '1px solid rgba(204, 204, 204, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
          position: 'sticky',
          top: `${
            !isEmpty(welcomeBarConfig) && welcomeBarConfig.wel_bar_pos == 1
              ? `${parseInt(welcomeBarConfig.bar_height) + 31}px`
              : '31px'
          }`,
          zIndex: zIndex.stickyH3,
        }}
        onMouseLeave={() => {
          // clearTimeout(timeoutId);
          // setTimeoutId(clearTimeout(timeoutId));
          // if (openMegaMenu) handleCloseMegaMenu();
          handleOpenMegaMenu({
            department: {},
            menuButtonXPosition: 0,
            closeDepartmentMenu: true,
          });
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: mainWidth,
          }}
        >
          <Stack
            className='header2-container'
            direction='row'
            sx={{
              height: '100%',
              // width: `${customerDetail?.shopByLookBook ? '85%' : '100%'}`,
              flex: '1',
              width: '200px',
            }}
          >
            <Box
              ref={boxRef}
              className='department-list-box-container'
              sx={[
                {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '25px',
                  // width: `${departmentContainerWidth}px`,
                  // width: 'fit-conent',
                  overflow: 'hidden',
                  position: 'relative',
                },
                departmentsLoading
                  ? { width: 'fit-content' }
                  : { width: `${departmentContainerWidth}px` },
              ]}
            >
              {!departmentsLoading ? (
                departments &&
                departments.map((department, index) => {
                  if (index == 0) {
                    SessionStorageManager.setSessionStorage(
                      'firstCollectionId',
                      department.collectionId
                    );

                    SessionStorageManager.setSessionStorage(
                      'firstDepartmentId',
                      department.genderId
                    );
                  }

                  return (
                    <Stack
                      className={`child-element department-${index}`}
                      key={department?.genderId}
                      id={department?.genderId}
                      direction='row'
                      spacing={1}
                      // variant='text'
                      ref={myRef}
                      sx={[
                        styles.departmentBtn,
                        selectedDepartment?.genderId == department?.genderId
                          ? styles.selectedDepartmentBtn
                          : null,
                        searchParams.get('gndr') == department?.genderId
                          ? {
                              color: 'var(--theme-color)',
                              borderColor: 'var(--theme-color)',
                            }
                          : null,
                      ]}
                      onMouseEnter={(e) => {
                        const elem = e.target;
                        const rect = elem.getBoundingClientRect();
                        const scrollTop = document.documentElement.offsetLeft;
                        const viewWidth = window.innerWidth; // get the width of the browser
                        let overFlowWidhtPx = 0;
                        if (rect.left + 700 >= viewWidth) {
                          // check if megamenu width of 700px exceeds the width of browser
                          // megamenu width = 700
                          // rect.left = this is the position of the menu button on the browser
                          //rect.left + 700 >= viewWidth = this is to check if megamenu exceeds the browser width
                          overFlowWidhtPx = rect.left + 700 - viewWidth; // checks how many px exceeds the browser and assigns it to overflowWIdthPx
                        }
                        const menuButtonXPosition =
                          scrollTop + rect.left - overFlowWidhtPx;
                        let departementData = {
                          ...department,
                          index,
                        };
                        handleOpenMegaMenu({
                          department: departementData,
                          menuButtonXPosition,
                          closeDepartmentMenu: false,
                        });
                      }}
                      onMouseLeave={() => {}}
                      onClick={() => handleDepartmentClick(department)}
                    >
                      {department?.logo_file && (
                        <img
                          src={department?.logo_file}
                          style={styles.departmentImg}
                        />
                      )}
                      {department?.genderName}
                    </Stack>
                  );
                })
              ) : (
                <Box display='flex' gap={2}>
                  <CircularProgress size={25} />
                </Box>
              )}
            </Box>
            <Button
              id='and-more'
              variant='text'
              sx={{
                display: 'none',
                whiteSpace: 'nowrap',
                height: '100%',
                paddingLeft: '8px',
                paddingRight: '8px',
              }}
              onClick={() => handleOpenMoreMenu()}
            >
              & MORE...
            </Button>
          </Stack>
          {/* shopbylookbooks and search */}
          {customerDetail?.shopByLookBook ? (
            <Stack
              direction='row'
              sx={{
                height: '100%',
                justifyContent: 'right',
                alignItems: 'center',
                // width: `${customerDetail?.shopByLookBook ? '15%' : '0%'}`,
                width: 'fit-content',
              }}
            >
              <Button
                variant='outlined'
                size='small'
                sx={styles.lookBooksButton}
                onClick={handleShopByLookBookClick}
              >
                SHOP BY LOOKBOOKS
              </Button>
            </Stack>
          ) : null}
        </Box>
        {openMegaMenu ? (
          <DepartmentMegaMenu
            selectedDepartment={selectedDepartment}
            handleCloseMenus={() => {
              handleOpenMegaMenu({
                department: {},
                menuButtonXPosition: 0,
                closeDepartmentMenu: true,
              });
            }}
            menuButtonXPosition={menuButtonXPosition}
            open={openMegaMenu}
          />
        ) : null}
        {openMoreMenu && (
          <DepartmentDrawerMenu
            departments={overflowItems}
            departmentsLoading={departmentsLoading}
            handleCloseMenus={handleCloseMoreMenu}
            handleDepartmentClick={handleDepartmentClick}
          />
        )}
      </Box>
    </Stack>
  );
};

DepartmentsBar.propTypes = {};

export default DepartmentsBar;
