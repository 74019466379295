import { api, apiEndPoints, addActionLoader, removeActionLoader } from 'api';
import {
  SET_ACTIVE_DRAFT_SUGGESTION,
  SET_DEPARTMENT_LIST,
} from '../actions/index';

const namespace = 'pages/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const GET_ACTIVE_DRAFT_SUGGESTION = `${namespace}GET_ACTIVE_DRAFT_SUGGESTION`;
export const SET_DEPARTMENT_LIST_REQUEST = `${namespace}SET_DEPARTMENT_LIST_REQUEST`;
export const USER_INFO_REQUEST = `${namespace}USER_INFO_REQUEST`;
export const SET_LOGGEDIN_USER_INFO = `${namespace}SET_LOGGEDIN_USER_INFO`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

// sets the user profile data in redux state
const setLoggedInUserInfo = (payload) => ({
  type: SET_LOGGEDIN_USER_INFO,
  payload,
});

//  Get Acive Draft And Suggestion
export const getActiveDraftSuggestion = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_ACTIVE_DRAFT_SUGGESTION));
    const response = await api.get(apiEndPoints.activeDraftSuggestion);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      let newData = {
        draft: {
          id: null,
          name: null,
          productCount: null,
          draftCount: null,
        },
        suggestion: {
          id: null,
          name: null,
          productCount: null,
        },
      };

      if (data.data.draft) newData.draft = data.data.draft;
      if (data.data.suggestion) newData.suggestion = data.data.suggestion;

      dispatch({ type: SET_ACTIVE_DRAFT_SUGGESTION, payload: { ...newData } });
    } else {
      // if (data !== []) {
      //   dispatch(
      //     showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
      //   );
      // }
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_ACTIVE_DRAFT_SUGGESTION));
  }
};

export const setDepartmentListItems =
  (data = []) =>
  async (dispatch) => {
    try {
      dispatch(addActionLoader(SET_DEPARTMENT_LIST_REQUEST));

      let newData = {
        list: [...data],
        commonGenderId: '',
        commonCollectionId: '',
      };
      if (data && data[0]) {
        newData.commonGenderId = data[0].genderId;
        newData.commonCollectionId = data[0].collectionId;
      }

      dispatch({ type: SET_DEPARTMENT_LIST, payload: { ...newData } });
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(SET_DEPARTMENT_LIST_REQUEST));
    }
  };

// gets the logged in user profile data
export const getUserInfo = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(USER_INFO_REQUEST));
    const response = await api.get(apiEndPoints.userInfo);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      dispatch(setLoggedInUserInfo(data));
      return status;
    }
    return status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(USER_INFO_REQUEST));
  }
};
