import { useState, useEffect, useCallback } from 'react';
import { api, apiEndPoints } from '../../../api';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
  useMatch,
} from 'react-router-dom';
import _debounce from 'lodash/debounce';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

export default function useCreateCustomerModal(data, edit, open, setOpen) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [showEditGroupDetails, setShowEditGroupDetails] = useState(false);
  const [buyersData, setBuyersData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [globalSearchValue, setGlobalSearchValue] = useState('');
  const [groupNameValue, setGroupNameValue] = useState('Group Name ##');
  const [groupDescriptionValue, setGroupDescriptionValue] = useState(
    'Your description here'
  );
  const [columnSearchValues, setColumnSearchValues] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [priceGroup, setPriceGroup] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [showBuyers, setShowBuyers] = useState('');
  const [filterString, setFilterString] = useState(
    edit ? `{"groupIds":["${data?.id}"]}` : ''
  );
  const [groups, setGroups] = useState([]);
  //  sort columns

  const [sortColumnValue, setSortColumnValue] = useState({
    columnName: '',
    sortValue: '',
  });

  // zero selected buyers alert modal state
  const [showZeroBuyerError, setShowZeroBuyerError] = useState(false);

  const [isTableDataLoading, setIsTableDataLoading] = useState(false);

  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    if (edit) {
      setGroupNameValue(data?.name);
      setGroupDescriptionValue(data?.description);
    } else {
      setGroupNameValue('Group Name ##');
      setGroupDescriptionValue('Your description here');
    }
  }, [open, data]);
  const [showApplyFiltersModal, setShowApplyFiltersModal] = useState(false);
  useEffect(() => {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        filters: '',
        filtersCount: '',
        columnSearchValues: '',
        // Update the filtersCount property
      });
      return updatedParams;
    });
    let payload = {
      page,
      rowsPerPage,
      globalSearchValue,
      filterString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    getBuyers(payload);
  }, [data]);

  //set selected buyers on initial load
  useEffect(() => {
    const arr = data?.buyer_ids?.split(',');
    if (Array.isArray(arr)) setSelectedBuyers(arr);
  }, [data]);

  const handleSelectAll = (buyersList) => {
    let newBuyerList = [];
    buyersList.forEach((buyer) => {
      if (!selectedBuyers.some((obj) => obj === buyer.id)) {
        newBuyerList.push(buyer.id);
      }
    });
    if (newBuyerList?.length > 0) {
      setSelectedBuyers((prev) => [...prev, ...newBuyerList]);
    }
  };
  async function getBuyers(payload) {
    if (edit && data?.id) {
      const res = await api.get(
        `${apiEndPoints.buyerList}?page=${payload?.page + 1}&limit=${
          payload?.rowsPerPage
        }&search=${payload?.globalSearchValue}&filters=${
          payload?.filterString
        }&sortByColumn=${payload?.sortByColumn}&sortBy=${
          payload?.sortValue
        }&showBuyerImage=true&isFromGroups=true`
      );

      if (res) {
        setIsTableDataLoading(false);
        setBuyersData(res?.data);
        if (isSelectAll) {
          handleSelectAll(res?.data?.data);
        }
        if (
          parseInt(res.data.count) == selectedBuyers.length &&
          parseInt(res.data.count) !== 0
        ) {
          if (!isSelectAll) setIsSelectAll(true);
        }
      } else {
        setIsTableDataLoading(false);
      }
    } else {
      const res = await api.get(
        `${apiEndPoints.buyerList}?page=${payload?.page + 1}&limit=${
          payload?.rowsPerPage
        }&search=${payload?.globalSearchValue}&filters=${
          payload?.filterString
        }&sortByColumn=${payload?.sortByColumn}&sortBy=${
          payload?.sortValue
        }&showBuyerImage=true&isFromGroups=true`
      );
      if (res) {
        setIsTableDataLoading(false);
        setBuyersData(res?.data);
        if (isSelectAll) {
          handleSelectAll(res?.data?.data);
        }
      } else {
        setIsTableDataLoading(false);
      }
    }
  }
  const getBuyerByDebounce = useCallback(
    _debounce((payload) => {
      getBuyers(payload);
    }, 1000),
    [data, edit, isSelectAll]
  );
  // sort buyers column

  //  logic to post group API
  async function onSaveHandler(setOpen) {
    let transdata = {
      name: groupNameValue,
      description: groupDescriptionValue,
      buyerIds: selectedBuyers,
    };
    const res = await api
      .post(apiEndPoints.groupList, transdata)
      .then((res) => {})
      .catch((error) => {});
    setOpen(false);
  }
  //    logic to handle onPageChange for pagination
  const onPageChangeHandler = (event, page) => {
    event.preventDefault();
    setPage(page);
    let payload = {
      page: page,
      rowsPerPage,
      globalSearchValue,
      filterString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    let payload = {
      page,
      rowsPerPage: parseInt(event.target.value, 10),
      globalSearchValue,
      filterString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  };
  // logic ends onpagechange
  //  logic to handle checkbox
  function handleCheck(value) {
    if (selectedBuyers.some((obj) => obj === value.id)) {
      setSelectedBuyers(() =>
        selectedBuyers.filter((item) => item !== value.id)
      );
    } else {
      setSelectedBuyers((prev) => {
        return [...prev, value.id];
      });
    }
  }
  const createColumnJsonString = (searchValues) => {
    let filters = '{';
    // if (!edit) filters = '{';
    // get column search filters
    searchValues?.forEach((obj) => {
      filters = filters.concat(`"${obj.label}":"${obj.value}",`);
    });

    filters = filters.substring(0, filters.length - 1);
    // if (!edit)
    filters = filters.concat('}');
    applyColumnSearchValues(filters);
  };

  const applyColumnSearchValues = (jsonFilterString) => {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        columnSearchValues: jsonFilterString ?? '', // Update the filtersCount property
      });
      return updatedParams;
    });
    let groupIdJSON = data?.id
      ? JSON.parse(`{"groupIds":["${data?.id}"]}`)
      : {};
    let columnSearchValues = JSON.parse(jsonFilterString);
    let filterString = searchParams?.get('filters')
      ? JSON.parse(searchParams?.get('filters'))
      : {};

    let combinedObject = searchParams?.get('filters')
      ? { ...groupIdJSON, ...columnSearchValues, ...filterString }
      : { ...groupIdJSON, ...columnSearchValues };
    let jsonString = JSON.stringify(combinedObject);
    setFilterString(jsonString);
    let payload = {
      page,
      rowsPerPage,
      globalSearchValue,
      filterString: jsonString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  };

  //apply filters
  function applyFilters(obj, filtersCount) {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        filters: obj ?? '',
        filtersCount: filtersCount ?? '', // Update the filtersCount property
      });
      return updatedParams;
    });
    let groupIdJSON = data?.id
      ? JSON.parse(`{"groupIds":["${data?.id}"]}`)
      : {};
    let columnSearchValues = searchParams.get('columnSearchValues')
      ? JSON.parse(searchParams.get('columnSearchValues'))
      : '';
    let filterString = obj ? JSON.parse(obj) : {};

    let combinedObject = searchParams.get('columnSearchValues')
      ? edit
        ? { ...groupIdJSON, ...columnSearchValues, ...filterString }
        : { ...columnSearchValues, ...filterString }
      : edit
      ? { ...groupIdJSON, ...filterString }
      : { ...filterString };
    let jsonString = JSON.stringify(combinedObject);
    setFilterString(jsonString);
    let payload = {
      page,
      rowsPerPage,
      globalSearchValue,
      filterString: jsonString,
      sortByColumn: sortColumnValue?.columnName,
      sortValue: sortColumnValue?.sortValue,
    };
    setIsTableDataLoading(true);
    getBuyerByDebounce(payload);
  }
  function clearFilters() {
    try {
      let arrColumnValues = [];
      arrColumnValues = columnSearchValues?.map((column) => {
        if (typeof column.value === 'object') {
          column.value.label = '';
          column.value.value = '';
        } else {
          column.value = '';
        }

        if (document.getElementById('box_' + column.label)) {
          document.getElementById('box_' + column.label).style.backgroundColor =
            '';
        }
        return column;
      });
      setColumnSearchValues(arrColumnValues);
      setGlobalSearchValue('');
      setSortColumnValue({
        columnName: '',
        sortValue: '',
      });
      let filterString = searchParams?.get('filters')
        ? JSON.parse(searchParams?.get('filters'))
        : {};
      let jsonString = JSON.stringify(filterString);
      setFilterString(jsonString);
      setSearchParams((prevParams) => {
        const updatedParams = createSearchParams({
          ...Object.fromEntries(prevParams), // Spread the existing search params
          columnSearchValues: '',
          // Update the filtersCount property
        });
        return updatedParams;
      });
      let payload = {
        page: page,
        rowsPerPage,
        globalSearchValue: '',
        filterString: searchParams?.get('filters') ? jsonString : '',
        sortByColumn: '',
        sortValue: '',
      };
      setIsTableDataLoading(true);
      getBuyerByDebounce(payload);
    } catch (err) {
      LoggingService.log(
        'clearFilters',
        'CreateCustomerGroupModal',
        err,
        LogLevel.ERROR,
        'This error logged from create-customer-group-modal.jsx file line no 123',
        {}
      );
    }
  }
  const handleClose = () => {
    setSearchParams((prevParams) => {
      const updatedParams = createSearchParams({
        ...Object.fromEntries(prevParams), // Spread the existing search params
        filters: '',
        filtersCount: '',
        columnSearchValues: '',
        // Update the filtersCount property
      });
      return updatedParams;
    });

    setFilterString('');
    setOpen(false);
  };
  return {
    onPageChangeHandler,
    handleChangeRowsPerPage,
    page,
    getBuyers,
    rowsPerPage,
    handleCheck,
    selectedBuyers,
    setSelectedBuyers,
    showEditGroupDetails,
    setShowEditGroupDetails,
    buyersData,
    globalSearchValue,
    setGlobalSearchValue,
    groupNameValue,
    setGroupNameValue,
    groupDescriptionValue,
    setGroupDescriptionValue,
    onSaveHandler,
    showApplyFiltersModal,
    setShowApplyFiltersModal,
    showZeroBuyerError,
    setShowZeroBuyerError,
    showBuyers,
    setShowBuyers,
    groups,
    setGroups,
    columnSearchValues,
    setColumnSearchValues,
    salesRep,
    setSalesRep,
    priceGroup,
    setPriceGroup,
    currency,
    setCurrency,
    filterString,
    setFilterString,
    applyFilters,
    isSelectAll,
    setIsSelectAll,
    handleClose,
    getBuyerByDebounce,
    isTableDataLoading,
    setIsTableDataLoading,
    sortColumnValue,
    setSortColumnValue,
    createColumnJsonString,
    clearFilters,
  };
}
