import {
  SET_LEFT_NAV_COLLECTION,
  SET_PRODUCT_VIEW_STYLE,
  SET_VISIBLE_COLLECTION_INFO,
} from './action';

const initialState = {
  leftNavCollection: [],
  productViewStyle: 'GRID',
  visibleCollection: {
    collectionId: null,
    viewportPercentage: 0,
  },
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEFT_NAV_COLLECTION:
      return {
        ...state,
        leftNavCollection: action.payload,
      };
    case SET_PRODUCT_VIEW_STYLE:
      return {
        ...state,
        productViewStyle: action.payload,
      };
    case SET_VISIBLE_COLLECTION_INFO:
      return {
        ...state,
        visibleCollection: action.payload,
      };
    default:
      return state;
  }
};

export default catalogReducer;
