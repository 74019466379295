import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api/index';
import { showSnackbar } from '../../components/snackbar/actions';
import { snackbarTypes } from '../../constants';

const namespace = 'pages/customers/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const UPLOAD_FILE = `${namespace}UPLOAD_FILE`;
export const GET_STYLE_NUMBER_COLUMNS = `${namespace}GET_STYLE_NUMBER_COLUMNS`;
export const SUBMIT_MAP_DATA = `${namespace}SUBMIT_MAP_DATA`;
export const VALIDATE_DATA = `${namespace}VALIDATE_DATA`;
export const FINAL_SUBMIT = `${namespace}FINAL_SUBMIT`;
export const ADD_UPDATE_MAP = `${namespace}ADD_UPDATE_MAP`;
export const SETUPSHEET_SUBMIT = `${namespace}SETUPSHEET_SUBMIT`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const uploadFileApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`UPLOAD_FILE`));

    const response = await api.post(apiEndPoints.orderImport, payload);
    const { data } = response;

    if (response?.data?.status === 200 || data.status) {
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response?.data?.message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    dispatch(setApiError(error));

    return false;
  } finally {
    dispatch(removeActionLoader(UPLOAD_FILE));
  }
};
//  on select options get columns data
export const getRemainingStyleNumberColumnsApi =
  (payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(`GET_STYLE_NUMBER_COLUMNS`));

      const response = await api.post(apiEndPoints.mapOrderData, payload);
      const { data } = response;

      if (response.status === 200 || data.status) {
        return data;
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_STYLE_NUMBER_COLUMNS));
    }
  };
//  Submit Map Data
export const submitMapDataApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`SUBMIT_MAP_DATA`));

    const response = await api.post(apiEndPoints.submitMapData, payload);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SUBMIT_MAP_DATA));
  }
};
//  Validate Map Data
export const validateDataApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`VALIDATE_DATA`));

    const response = await api.post(apiEndPoints.validateOrderData, payload);
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(VALIDATE_DATA));
  }
};
//  Final Submit Data
export const finalSubmit = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`FINAL_SUBMIT`));

    const response = await api.post(
      apiEndPoints.finalSubmissionOrderImport,
      payload
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(FINAL_SUBMIT));
  }
};
//  ==============================> setup sheet api <=======================================
//  Add Update Map Data Api
export const addUpdataMapDataApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`ADD_UPDATE_MAP`));

    const response = await api.post(apiEndPoints.addUpdateMapData, payload);
    const { data } = response;
    if (response?.data?.errors) {
      let errormsg = '';
      response?.data?.errors.forEach((item) => {
        for (const key in item) {
          errormsg = `${errormsg} ${errormsg === '' ? '' : ','} ${item[key]}`;
        }
      });
      dispatch(
        showSnackbar({
          snackbarMessage: errormsg,
          type: snackbarTypes.ERROR,
        })
      );
    }
    if (response.status === 200 || data.status) {
      return data;
    }

    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_UPDATE_MAP));
  }
};
//  submit setupsheet
export const submitSetupSheetApi = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`SETUPSHEET_SUBMIT`));

    const response = await api.post(
      apiEndPoints.setupSheetFinalSubmission,
      payload
    );
    const { data } = response;

    if (response.status === 200 || data.status) {
      return data;
    }
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(SETUPSHEET_SUBMIT));
  }
};
