import RecoverAccountForm from './recover-account-form';
import AuthPage from '../components/auth-page';
import { useRecoverAccount } from './reset-password-hooks';
import RecoverConfirmation from './recover-confirmation';

const RecoverAccount = () => {
  const {
    handleRecoverMessage,
    showRecoverMessage,
    customerDetails,
    responseMessage,
  } = useRecoverAccount();
  return showRecoverMessage ? (
    <RecoverConfirmation
      logo={customerDetails?.media?.logo}
      message={responseMessage}
    />
  ) : (
    <AuthPage
      showWelcomeMessage1
      showWelcomeMessage2
      showBackToLogin
      height='100vh'
    >
      <RecoverAccountForm handleRecoverMessage={handleRecoverMessage} />
    </AuthPage>
  );
};

export default RecoverAccount;
