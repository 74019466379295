import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from 'components/text-field';
import { Formik } from 'formik';
import * as Yup from 'yup';

import useAddContacts from './add-contacts-hook';
import CopySalesTeam from './copy-sales-team';
import SelectSenderSignature from 'components/Select-Sender-Signature/select-sender-signature';
import { responsiveBreakpoints } from 'utils/utils';

export default function AddContacts({
  selectedAssociates,
  setSelectedAssociates,
  selectedSender,
  setSelectedSender,
  selectedSignature,
  setSelectedSignature,
  showComponents,
  setMessageAndSubject,
}) {
  const {
    selectedItems,
    setSelectedItems,
    onSubmitFormikHandler,
    emailData,
    formikRef,
    deleteEmailQuery,
    user,
  } = useAddContacts({ selectedAssociates, setSelectedAssociates });
  const { mobile } = responsiveBreakpoints();
  return (
    <Grid container sx={{ width: '100%' }}>
      {/* Add contacts grid */}
      {showComponents.addContactsComponent ? (
        <Grid item xs={12} sm={6} sx={{ padding: '5px 10px' }}>
          <Typography
            variant='body2'
            sx={{ fontWeight: '900', fontSize: '14px', marginBottom: '5px' }}
          >
            Add Contacts
          </Typography>
          <Stack
            spacing={1.5}
            sx={{
              border: '1px solid #CDCCD6',
              //   padding: '8px 13px'
            }}
          >
            <Stack spacing={0.5} sx={{ padding: '8px 13px 0px 13px' }}>
              <Formik
                innerRef={formikRef}
                validateOnMount
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Invalid Email Format')
                    .required('Please enter Email'),
                })}
                initialValues={{ email: '' }}
                onSubmit={onSubmitFormikHandler}
              >
                {({ ...rest }) => {
                  return (
                    <Stack direction='row' spacing={2}>
                      <TextField
                        label=''
                        placeholder='Enter Email'
                        inputSize='90%'
                        id='email'
                        name='email'
                        value={rest.values.email}
                        onChange={rest.handleChange}
                        error={rest.touched.email && Boolean(rest.errors.email)}
                        helperText={rest.touched.email && rest.errors.email}
                      />
                      <Button
                        variant='contained'
                        onClick={() => {
                          // formikRef.current.submitForm();
                          onSubmitFormikHandler(rest.values);
                          rest.setFieldValue('email', '');
                        }}
                      >
                        Add
                      </Button>
                    </Stack>
                  );
                }}
              </Formik>
            </Stack>

            <Stack
              sx={{
                height: '145px',
                width: '100%',
                overflowY: 'auto',
              }}
            >
              {emailData?.map((email, i) => (
                <Box
                  key={i}
                  sx={{
                    borderBottom: '1px solid var(--primary-border-color)',
                    padding: '5px 13px',
                  }}
                >
                  <Stack
                    direction='row'
                    spacing={1}
                    sx={{ alignItems: 'center' }}
                  >
                    <Checkbox
                      checked={(() => {
                        var x = false;
                        selectedItems.forEach((item) => {
                          if (item.id === email.id) x = true;
                        });
                        return x;
                      })()}
                      onClick={() => {
                        if (selectedItems.some((obj) => obj.id === email.id)) {
                          setSelectedItems(() =>
                            selectedItems.filter((item) => item.id !== email.id)
                          );
                        } else {
                          setSelectedItems((prev) => {
                            return [
                              ...prev,
                              {
                                id: email.id,
                                contact_email: email.contact_email,
                              },
                            ];
                          });
                        }
                      }}
                    />
                    <Typography variant='body1'>
                      {email.contact_email}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Stack>
            <Stack sx={{ padding: '2px 13px 8px 13px' }}>
              <Button
                variant='outlined'
                sx={{ width: 'fit-content' }}
                onClick={() => deleteEmailQuery.mutate()}
              >
                Remove Selected
              </Button>
            </Stack>
          </Stack>
        </Grid>
      ) : null}
      {showComponents.copyAssociateComponent ? (
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            padding: mobile ? '5px 0px' : '5px 10px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant='body2'
            sx={{ fontWeight: '900', fontSize: '14px', marginBottom: '5px' }}
          >
            Copy Sales Team
          </Typography>
          <Stack sx={{ flex: 1 }}>
            {user?.roleId === '3' ? null : (
              // <CopyAssociate
              //   selectedAssociates={selectedAssociates}
              //   setSelectedAssociates={setSelectedAssociates}
              //   sx={{ height: '267px' }}
              // />
              <CopySalesTeam
                selectedAssociates={selectedAssociates}
                setSelectedAssociates={setSelectedAssociates}
                sx={{
                  height: '265px',
                  border: '1px solid #CDCCD6',
                }}
              />
            )}
          </Stack>
        </Grid>
      ) : null}
      {showComponents?.senderSignatureComponent ? (
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography variant='h2' sx={{ fontWeight: '900' }}>
              Select Sender and Signature
            </Typography>
            <SelectSenderSignature
              selectedSender={selectedSender}
              setSelectedSender={setSelectedSender}
              selectedSignature={selectedSignature}
              setSelectedSignature={setSelectedSignature}
              setMessageAndSubject={setMessageAndSubject}
            />
          </Stack>
        </Grid>
      ) : null}
    </Grid>
  );
}
AddContacts.propTypes = {
  selectedAssociates: PropTypes.array.isRequired,
  setSelectedAssociates: PropTypes.func.isRequired,
  selectedSender: PropTypes.string,
  setSelectedSender: PropTypes.func,
  selectedSignature: PropTypes.string,
  setSelectedSignature: PropTypes.func,
  showComponents: PropTypes.object,
  setMessageAndSubject: PropTypes.func,
};
AddContacts.defaultProps = {
  selectedAssociates: [],
  setSelectedAssociates: () => {},
  selectedSender: '',
  setSelectedSender: () => {},
  selectedSignature: '',
  setSelectedSignature: () => {},
  showComponents: {},
  setMessageAndSubject: () => {},
};
