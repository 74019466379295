import React from 'react';
import PropTypes from 'prop-types';

export default function SuggestionIcon({ fill, sx, width, height }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15.882 26'
      fill={fill}
      width={width}
      height={height}
      style={sx}
    >
      <g transform='translate(-1113 -90.001)'>
        <g transform='translate(1113 90.001)'>
          <path
            d='M128.827,38.453a7.941,7.941,0,0,0-5.584,13.47,6.5,6.5,0,0,1,1.875,4.534v4.171a3.824,3.824,0,0,0,7.647,0V56.457a6.4,6.4,0,0,1,1.845-4.5,7.941,7.941,0,0,0-5.783-13.5Zm-1.944,18.94H131v1.8h-4.118Zm2.059,5.294a2.062,2.062,0,0,1-2.033-1.733h4.065A2.062,2.062,0,0,1,128.941,62.687Zm2.572-12.209c-1.32,1.345-.286,3.305-.471,5.151h-4.2c4.2-.019,5.492.528,4.16-.844-.724-1.389-1.8-2.455-1.781-4.09.029-3.328-3.72-.169-.392-.216.03,0,1.93,5.151,1.96,5.151,1.634,0,.568-4.823,1.733-3.675,1.187,1.17-1.006-7.6-1.006-5.937C131.513,47.644,132.652,49.316,131.513,50.478Z'
            transform='translate(-121 -38.452)'
          />
        </g>
      </g>
    </svg>
  );
}

SuggestionIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
};

SuggestionIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  width: '15.882px',
  height: '26px',
  sx: {},
};
