import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { departmentListSelector } from '../../redux/selectors/index';
import { getActiveDraftSuggestion } from '../../redux/actions/global-actions';
import SessionStorageManager from 'utils/session-storage-manager';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';

export default function useSuggestions() {
  const dispatch = useDispatch();
  const { updateURLParams } = useUpdateURLParams();
  const departmentList = useSelector(departmentListSelector);
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState(null);
  const [showNewSuggestionModal, setShowNewSuggestionModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  // get suggestions
  async function getSuggestions() {
    const res = await api.get(`/suggestionList?page=${pageNumber}`);
    setSuggestions(res.data);
    return res;
  }

  // create suggestion
  async function createSuggestion(name, description, setOpen) {
    const res = await api.post(`/createSuggestion`, {
      name: name,
      description: description,
    });

    if (res.data.status) {
      dispatch(getActiveDraftSuggestion());
      navigate(`/suggestions/${res.data.suggestionId}?resetCatalog=true`);
      //close quick panel
      setOpen(false);
    }
  }

  //delete suggestion
  async function deleteSuggestion(id, setOpen) {
    const res = await api.delete(`/deleteSuggestion?id=${id}`);
    if (res.data.status) {
      //updateURLParams({ resetCatalog: true });
      dispatch(getActiveDraftSuggestion());
      let suggestionData = await getSuggestions();

      if (suggestionData.status) {
        navigate(`/suggestions/${suggestionData.data.data[0].id}`);
      }
    }
  }

  //bulk delete suggestions
  async function bulkDeleteSuggestions(ids, setOpen) {
    const res = await api.delete(`/bulkdeleteSuggestions`, {
      data: { id: ids },
    });
    if (res.data.status) {
      //updateURLParams({ resetCatalog: true });
      dispatch(getActiveDraftSuggestion());
      let suggestionData = await getSuggestions();

      if (suggestionData.status) {
        navigate(`/suggestions/${suggestionData.data.data[0].id}`);
      }
    }
  }

  //set active suggestion
  async function setActiveSuggestion(id) {
    const res = await api.get(`updateActiveSuggestion?linesheet_id=${id}`);
    if (res.data.status) {
      getSuggestions();
      dispatch(getActiveDraftSuggestion());
    }
  }

  // fetch
  useEffect(() => {
    getSuggestions();
  }, [pageNumber]);

  return {
    suggestions,
    createSuggestion,
    deleteSuggestion,
    setActiveSuggestion,
    showNewSuggestionModal,
    setShowNewSuggestionModal,
    pageNumber,
    setPageNumber,
    bulkDeleteSuggestions,
  };
}
