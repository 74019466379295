import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { zIndex } from 'constants/zIndex';
import { responsiveBreakpoints } from 'utils/utils';

export default function MyModal({
  open,
  setOpen,
  children,
  title,
  subTitle,
  editIconButton,
  showCloseIcon,
  size,
  sx,
  actions,
  modalBoxStyle,
  disableScrollLock,
  noPadding,
  customTitle,
  isCustomTitle,
}) {
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const { mobile, tablet } = responsiveBreakpoints();
  return (
    <Modal
      disableScrollLock={disableScrollLock}
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: zIndex?.myModal ?? 999,
      }}
    >
      <Box
        sx={[
          {
            backgroundColor: 'var(--paper-background-color)',
            border: 0,
            borderRadius: '6px',
            outline: 'none',
            ...modalBoxStyle,
            overflow: 'hidden',
          },
          size === 'fullWidth'
            ? {
                width: '100%',
                height: '100%',
                borderRadius: 0,
                backgroundColor: 'var(--page-background-color)',
                ...modalBoxStyle,
              }
            : null,
          size === 'custom'
            ? {
                width: '53%',
                height: 'fit-content',
                borderRadius: 0,
                padding: '0px 10px !important',
                backgroundColor: 'var(--page-background-color)',
                ...modalBoxStyle,
              }
            : null,
        ]}
      >
        {(title && !isCustomTitle) || showCloseIcon ? (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={[
              {
                borderBottom: '1px solid var(--secondary-border-color)',
                backgroundColor: 'var(--modal-header-color)',
              },
              size === 'small'
                ? {
                    padding:
                      mobile || tablet ? '0 10px 0 10px' : '0 19px 0 19px',
                    height: '52px',
                  }
                : null,
              size === 'medium'
                ? {
                    padding:
                      mobile || tablet ? '0 10px 0 10px' : '0 19px 0 19px',
                    height: '52px',
                  }
                : null,
              size === 'large'
                ? {
                    padding:
                      mobile || tablet ? '0 10px 0 10px' : '0 25px 0 25px',
                    height: '52px',
                  }
                : null,
              size === 'fullWidth'
                ? {
                    padding:
                      mobile || tablet ? '0 10px 0 10px' : '0 100px 0 100px',
                    height: '60px',
                  }
                : null,
              size === 'custom'
                ? {
                    backgroundColor: 'var(--paper-background-color)',
                    padding: '15px 12px',
                  }
                : null,
            ]}
          >
            <Stack>
              <Typography
                sx={[
                  { color: 'var(--primary-font-color)' },
                  size === 'small'
                    ? {
                        fontWeight: 900,
                        fontSize: '16px',
                      }
                    : null,
                  size === 'medium'
                    ? {
                        fontWeight: 700,
                        fontSize: '16px',
                      }
                    : null,
                  size === 'large'
                    ? {
                        fontWeight: 900,
                        fontSize: '16px',
                      }
                    : null,
                  size === 'fullWidth'
                    ? {
                        fontWeight: 900,
                        fontSize: '20px',
                      }
                    : null,
                  size === 'custom'
                    ? {
                        fontWeight: 900,
                        fontSize: '14px',
                      }
                    : null,
                ]}
              >
                {title} {editIconButton}
              </Typography>
              {subTitle ? (
                <Typography
                  variant='body2'
                  sx={{
                    color: ' #313541',
                    opacity: '0.65',
                  }}
                >
                  {subTitle}
                </Typography>
              ) : null}
            </Stack>
            {showCloseIcon ? (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleClose(e);
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Stack>
        ) : null}
        {isCustomTitle ? (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={[
              {
                borderBottom: '1px solid var(--secondary-border-color)',
                backgroundColor: 'var(--modal-header-color)',
              },
              size === 'small'
                ? {
                    padding: '0 19px 0 19px',
                    minHeight: '52px',
                  }
                : null,
              size === 'medium'
                ? {
                    padding: '0 19px 0 19px',
                    minHeight: '52px',
                  }
                : null,
              size === 'large'
                ? {
                    padding: '0 25px 0 25px',
                    minHeight: '52px',
                  }
                : null,
              size === 'fullWidth'
                ? {
                    padding: '0 100px 0 100px',
                    minHeight: '60px',
                  }
                : null,
              size === 'custom'
                ? {
                    backgroundColor: 'var(--paper-background-color)',
                    padding: '15px 12px',
                  }
                : null,
            ]}
          >
            {customTitle}
            {showCloseIcon ? (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  handleClose(e);
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Stack>
        ) : null}

        <Box
          sx={[
            {
              height: '100%',
              overflowY: 'scroll',
            },
            size === 'small' ? { padding: '15px 20px 15px 20px' } : null,
            size === 'medium' ? { padding: '15px 20px 15px 20px' } : null,
            size === 'large' ? { padding: '20px 25px 26px 25px' } : null,
            size === 'fullWidth'
              ? noPadding
                ? { padding: '0 0px 115px 0px', height: '100%' }
                : {
                    padding:
                      mobile || tablet
                        ? '0 0px 115px 0px'
                        : '0 82px 115px 82px',
                    height: '100%',
                  }
              : null,
            sx,
          ]}
        >
          {children}
        </Box>

        {actions ? (
          <Stack
            direction='row'
            spacing={0.5}
            justifyContent='right'
            alignItems='center'
            sx={[
              {
                borderTop: '1px solid var(--secondary-border-color)',
                padding: '7px 25px 7px 25px',
                backgroundColor: 'var(--modal-header-color)',
                width: '100%',
                minHeight: '53px',
              },
              size === 'fullWidth'
                ? mobile
                  ? {
                      position: 'absolute',
                      bottom: 0,
                      padding: '7px 28px 7px 28px',
                    }
                  : {
                      position: 'absolute',
                      bottom: 0,
                      padding: '7px 100px 7px 100px',
                    }
                : null,
            ]}
          >
            {actions.map((action, i) => {
              return <Box key={i}>{action}</Box>;
            })}
          </Stack>
        ) : null}
      </Box>
    </Modal>
  );
}

MyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  showCloseIcon: PropTypes.bool,
  showPrimaryAction: PropTypes.bool,
  closeButtonText: PropTypes.string,
  primaryActionText: PropTypes.string,
  size: PropTypes.string.isRequired,
  sx: PropTypes.object,
  actions: PropTypes.array,
  subTitle: PropTypes.string,
  editIconButton: PropTypes.node,
  showPrimaryActionOnClick: PropTypes.func,
  modalBoxStyle: PropTypes.object,
  disableScrollLock: PropTypes.bool,
  noPadding: PropTypes.bool,
  customTitle: PropTypes.node,
  isCustomTitle: PropTypes.bool,
};

MyModal.defaultProps = {
  title: null,
  showCloseIcon: false,
  sx: {},
  actions: null,
  subTitle: null,
  editIconButton: null,
  showPrimaryActionOnClick: () => {},
  modalBoxStyle: {},
  disableScrollLock: true,
  noPadding: false,
  customTitle: <></>,
  isCustomTitle: false,
};
