import { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LocationIcon from '../../assets/icons/location-icon';
import EmailIcon from '../../assets/icons/email-icon';
import PhoneIcon from '../../assets/icons/phone-icon';
import FacebookIcon from '../../assets/icons/facebook-icon';
import InstagramIcon from '../../assets/icons/instagram-icon';
import TwitterIcon from '../../assets/icons/twitter-icon';
import YouTubeIcon from '../../assets/icons/youtube-icon';
import { useNavigate } from 'react-router-dom';
import { selectCustomerDetailSelector } from '../../redux/selectors';
import isEmpty from 'lodash/isEmpty';
import { useHeader3 } from './header-hooks';
import Tnc from 'components/tnc';
import useFooter from './footer-hooks';
import { logOut } from 'utils/auth';
import { useDepartmentsBar } from './department-bar/department-bar-hooks';
import DepartmentDrawerMenu from './department-bar/department-drawer/department-drawer-menu';
import { responsiveBreakpoints } from 'utils/utils';
import { useHeader2M } from './components/hooks';
import DepartmentMenuMobile from './components/department-mobile/department-m';

function FooterCol({ header, items }) {
  const { showTerms, setShowTerms, terms } = useFooter();
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();
  const navigate = useNavigate();
  return (
    <Box
      sx={[
        mobile
          ? { display: 'flex', flexDirection: 'column' }
          : {
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            },
      ]}
    >
      <Tnc open={showTerms} setOpen={setShowTerms} tnc={terms} />
      <Typography
        variant='h2'
        sx={{
          fontSize: mobile ? '14px' : '15px',
          textTransform: 'uppercase',
          color: '#fff',
          opacity: 0.7,
          marginBottom: '10px',
        }}
      >
        {header}
      </Typography>
      <Stack spacing={1.2}>
        {items.map((item, i) => {
          if (item.label === 'Terms and Conditions')
            return (
              <Typography
                size='small'
                key={i}
                variant='text'
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowTerms(!showTerms);
                }}
              >
                {item.label}
              </Typography>
            );
          else if (item.label === 'Logout')
            return (
              <Typography
                size='small'
                key={i}
                variant='text'
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  logOut();
                }}
              >
                {item.label}
              </Typography>
            );
          else if (item?.target == true)
            return (
              <Typography
                size='small'
                key={i}
                variant='text'
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                }}
                onClick={() => {
                  window.open(`${item.href}`, '_blank');
                }}
              >
                {item.label}
              </Typography>
            );
          else
            return (
              <Typography
                size='small'
                key={i}
                href={item.href}
                variant='text'
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                }}
                onClick={() => {
                  navigate(item?.href);
                  window.scrollTo(0, 0);
                }}
              >
                {item.label}
              </Typography>
            );
        })}
      </Stack>
    </Box>
  );
}

FooterCol.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

function DepartmentCol({ items }) {
  const navigate = useNavigate();
  const {
    handleOpenMoreMenu,
    departments,
    departmentsLoading,
    handleOpenMegaMenu,
    handleCloseMegaMenu,
    handleCloseMoreMenu,
    openMegaMenu,
    openMoreMenu,
    selectedDepartment,
    customerDetail,
    handleShopByLookBookClick,
    handleDepartmentClick,
  } = useDepartmentsBar();
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();
  const { showDepartmentMobileMenu, setShowDepartmentMobileMenu } =
    useHeader2M();
  return (
    <Box
      sx={[
        mobile
          ? { display: 'flex', flexDirection: 'column' }
          : {
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            },
      ]}
    >
      <Typography
        variant='h2'
        sx={{
          fontSize: mobile ? '14px' : '15px',
          textTransform: 'uppercase',
          color: '#fff',
          opacity: 0.7,
          marginBottom: '10px',
        }}
      >
        DEPARTMENTS
      </Typography>
      <Stack direction='row' spacing={2}>
        <Stack spacing={1.2}>
          {(items || []).map((item, i) =>
            i < 5 ? (
              <Typography
                variant='body2'
                key={item.genderId}
                onClick={() => {
                  navigate({
                    pathname: '/catalog',
                    search: `?gndr=${item.genderId}&cl=${item.collectionId}&start=0`,
                  });
                }}
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                  whiteSpace: 'nowrap',
                }}
              >
                {item.genderName}
              </Typography>
            ) : null
          )}
        </Stack>
        <Stack spacing={1.2}>
          {(items || []).map((item, i) =>
            i > 4 && i < 9 ? (
              <Typography
                variant='body2'
                key={item.genderId}
                onClick={() => {
                  navigate({
                    pathname: '/catalog',
                    search: `?gndr=${item.genderId}&cl=${item.collectionId}&start=0`,
                  });
                }}
                sx={{
                  cursor: 'pointer',
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                  whiteSpace: 'nowrap',
                }}
              >
                {item.genderName}
              </Typography>
            ) : i === 9 ? (
              <Typography
                key={i}
                id='and-more1'
                variant='text'
                onClick={() => {
                  mobile
                    ? setShowDepartmentMobileMenu(true)
                    : handleOpenMoreMenu();
                  window.scroll(0, 0);
                }}
                sx={{
                  justifyContent: 'left',
                  fontSize: mobile ? '12px' : '14px',
                  fontWeight: 300,
                  width: 'fit-content',
                  textAlign: 'left',
                  padding: 0,
                  color: '#fff',
                  opacity: 0.7,
                  marginBottom: '10px',
                  whiteSpace: 'nowrap',
                }}
              >
                & MORE...
              </Typography>
            ) : null
          )}
        </Stack>
      </Stack>
      {openMoreMenu && (
        <DepartmentDrawerMenu
          departments={departments}
          departmentsLoading={departmentsLoading}
          handleCloseMenus={handleCloseMoreMenu}
          handleDepartmentClick={handleDepartmentClick}
        />
      )}
      <DepartmentMenuMobile
        open={showDepartmentMobileMenu}
        setOpen={setShowDepartmentMobileMenu}
      />
    </Box>
  );
}

DepartmentCol.propTypes = {
  header: PropTypes.string,
  items: PropTypes.array.isRequired,
};

DepartmentCol.defaultProps = {
  header: '',
};

export default function Footer() {
  const config = useSelector(selectCustomerDetailSelector);
  const { department } = useHeader3();
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();
  const footerData = [
    {},
    {
      header: 'My Account',
      items: [
        {
          label: 'My Account',
          href: '/profile',
        },
        {
          label: 'View Draft',
          href: '/drafts',
        },
        {
          label: 'My Suggestions',
          href: '/suggestions',
        },
        {
          label: 'Logout',
          href: '',
        },
      ],
    },
    {
      header: 'Our Policies',
      items: [
        {
          label: 'About Us',
          href: '/help/contact',
        },
        {
          label: 'Help Center',
          href: '/help',
        },
        {
          label: 'Contact Us',
          href: '/help/support',
        },
        {
          label: 'Terms and Conditions',
          href: '#',
        },
        {
          label: 'MyAtOnce ' + config.current_year,
          href: 'https://myatonce.com',
          target: true,
        },
      ],
    },
  ];
  if (!isEmpty(config))
    return (
      <Box
        sx={[
          mobile
            ? {
                padding: '35px 0px 60px 20px',
                backgroundColor: '#1a1a1a',
                color: 'var(--color-white)',
                display: 'flex',
                width: '100vw',
                height: 'fit-content',
              }
            : {
                padding: '60px 0px 40px 0px',
                backgroundColor: '#1a1a1a',
                color: 'var(--color-white)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
        ]}
      >
        <Box sx={[mobile ? { width: '100%' } : { width: mainWidth }]}>
          <Grid container sx={{ paddingBottom: '30px' }} rowSpacing={4}>
            <Grid item xs={12} sm={3}>
              <Stack spacing={0} alignItems='start'>
                {config.name ? (
                  <Typography
                    variant='h2'
                    sx={{
                      marginBottom: '10px',
                      fontSize: mobile ? '14px' : '15px',
                      color: '#fff',
                      opacity: 0.7,
                    }}
                  >
                    {config.name.toUpperCase()}
                  </Typography>
                ) : null}

                {config.address ? (
                  <Button
                    size='small'
                    type='text'
                    href={`https://www.google.com/maps/search/${config.address.replace(
                      / /g,
                      '+'
                    )}`}
                    startIcon={<LocationIcon fill='var(--color-white)' />}
                    sx={{
                      fontSize: mobile ? '12px' : '14px',
                      fontWeight: 300,
                      color: '#fff',
                      opacity: 0.7,
                    }}
                  >
                    {config.address}
                  </Button>
                ) : null}
                {config.phone ? (
                  <Button
                    size='small'
                    type='text'
                    href={`tel:${config.phone}`}
                    startIcon={<PhoneIcon fill='var(--color-white)' />}
                    sx={{
                      fontSize: mobile ? '12px' : '14px',
                      fontWeight: 300,
                      color: '#fff',
                      opacity: 0.7,
                    }}
                  >
                    {config.phone}
                  </Button>
                ) : null}

                {config.email ? (
                  <Button
                    size='small'
                    type='text'
                    href={`mailto:${config.email}`}
                    startIcon={<EmailIcon fill='var(--color-white)' />}
                    sx={{
                      fontSize: mobile ? '12px' : '14px',
                      fontWeight: 300,
                      color: '#fff',
                      opacity: 0.7,
                    }}
                  >
                    {config.email}
                  </Button>
                ) : null}

                <Stack direction='row' spacing={2} sx={{ marginTop: '10px' }}>
                  {config.facebook && config.facebook !== '' ? (
                    <a
                      href={`https://${config.facebook}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <IconButton>
                        <FacebookIcon
                          fill='var(--color-white)'
                          style={{ opacity: 0.7 }}
                        />
                      </IconButton>
                    </a>
                  ) : null}
                  {config.instagram && config.instagram !== '' ? (
                    <a
                      href={`https://${config.instagram}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <IconButton>
                        <InstagramIcon
                          fill='var(--color-white)'
                          style={{ opacity: 0.7 }}
                        />
                      </IconButton>
                    </a>
                  ) : null}
                  {config.twitter && config.twitter !== '' ? (
                    <a
                      href={`https://${config.twitter}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <IconButton>
                        <TwitterIcon
                          fill='var(--color-white)'
                          style={{ opacity: 0.7 }}
                        />
                      </IconButton>
                    </a>
                  ) : null}
                  {config.youtube && config.youtube !== '' ? (
                    <a
                      href={`https://${config.youtube}`}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      <IconButton>
                        <YouTubeIcon
                          fill='var(--color-white)'
                          style={{ opacity: 0.7 }}
                        />
                      </IconButton>
                    </a>
                  ) : null}
                </Stack>
              </Stack>
            </Grid>

            {footerData.map((col, i) => (
              <Grid key={i} item xs={6} sm={3}>
                {i === 0 ? (
                  <DepartmentCol items={department ?? []} />
                ) : (
                  <FooterCol header={col.header} items={col.items} />
                )}
              </Grid>
            ))}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Stack
                direction='row'
                spacing={1}
                sx={[
                  mobile
                    ? {
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }
                    : null,
                ]}
              >
                <Typography
                  sx={{
                    fontSize: '13px',
                    opacity: 0.7,
                    color: '#fff',
                    whiteSpace: 'nowrap',
                  }}
                >
                  &copy;{' '}
                  <a
                    href='https://myatonce.com'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: '#fff' }}
                  >
                    MyAtOnce
                  </a>{' '}
                  {config.current_year}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    opacity: 0.7,
                    color: '#fff',
                    whiteSpace: 'nowrap',
                  }}
                >
                  |
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    opacity: 0.7,
                    color: '#fff',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <a
                    href={config.website}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: '#fff' }}
                  >
                    {config.name}
                  </a>{' '}
                  {config.current_year}.
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    opacity: 0.7,
                    color: '#fff',
                    whiteSpace: 'nowrap',
                  }}
                >
                  All Rights Reserved.
                </Typography>
              </Stack>
            </Grid>
            {/*<Grid item xs={12} sm={6}>
            <Stack direction='row' spacing={2} justifyContent='right'>
              <Typography
                sx={{ fontSize: '13px', color: '#fff', opacity: 0.7 }}
              >
                Terms of Sale
              </Typography>
              <Typography
                sx={{ fontSize: '13px', color: '#fff', opacity: 0.7 }}
              >
                |
              </Typography>
              <Typography
                sx={{ fontSize: '13px', color: '#fff', opacity: 0.7 }}
              >
                Terms of Use
              </Typography>
              <Typography
                sx={{ fontSize: '13px', color: '#fff', opacity: 0.7 }}
              >
                |
              </Typography>
              <Typography
                sx={{ fontSize: '13px', color: '#fff', opacity: 0.7 }}
              >
                Privacy Policy
              </Typography>
            </Stack>
          </Grid>*/}
          </Grid>
        </Box>
      </Box>
    );
}
