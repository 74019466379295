import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { getUserInfo, USER_INFO_REQUEST } from 'redux/actions/global-actions';
import {
  selectCustomerDetailSelector,
  loggedInUserSelector,
  actionLoaderSelector,
} from 'redux/selectors';
import {
  getCustomerSupportData,
  getDepartmentCatagories,
  getDepartmentLogo,
} from './actions';
import { fetchClientOptions } from '../app/actions';
import LocalStorageManager from 'utils/local-storage-manager';
import { INITIAL_PATHNAME, INITIAL_SEARCH } from 'constants/index';

export const useLayout = () => {
  const navigate = useNavigate();
  const customerDetails = useSelector(selectCustomerDetailSelector);

  const handleDashboardClick = () => {
    // history.push(ScreenNames.Dashboard);
  };

  const checkInitialNavigation = () => {
    let initialPathname = LocalStorageManager.getLocalStorage(INITIAL_PATHNAME);
    let initialSearch = LocalStorageManager.getLocalStorage(INITIAL_SEARCH);
    LocalStorageManager.removeLocalStorage(INITIAL_PATHNAME);
    LocalStorageManager.removeLocalStorage(INITIAL_SEARCH);
    if (initialPathname || initialSearch) {
      initialPathname = initialPathname
        ? initialPathname.replace(/["']/g, '')
        : '';
      initialSearch = initialSearch ? initialSearch.replace(/["']/g, '') : '';
      navigate(`${initialPathname}${initialSearch}`, { state: initialSearch });
    }
  };

  useEffect(() => {
    checkInitialNavigation();
  }, []);

  return {
    customerDetails,
    handleDashboardClick,
  };
};

export const useLoginUserInfo = () => {
  const dispatch = useDispatch();
  const isLoggedInUserLoading = useSelector(
    actionLoaderSelector(USER_INFO_REQUEST)
  );
  const loggedInUserDetails = useSelector(loggedInUserSelector);

  useEffect(() => {
    const fetchLoggedInUserInfo = async () => {
      await dispatch(getUserInfo());
      await dispatch(getCustomerSupportData());
      await dispatch(fetchClientOptions());
    };
    fetchLoggedInUserInfo();
  }, []);

  return {
    loggedInUserDetails,
    isLoggedInUserLoading,
  };
};

export const useHeader3 = () => {
  const [department, setDepartment] = useState(null);
  const [departmentCollections, setDepartmentCollections] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [collectionId, setCollectionId] = useState('');
  const [moreOptions, setMoreOptions] = useState(false);

  const [selectedElement, setSelectedElement] = useState('');

  const showMegaMenu = () => {
    if (document.getElementById('mega-menu'))
      document.getElementById('mega-menu').style.display = 'block';
  };

  const hideMegaMenu = () => {
    if (document.getElementById('mega-menu'))
      document.getElementById('mega-menu').style.display = 'none';
  };
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    location.pathname !== '/catalog' ? setSelectedElement('') : null;
  }, [location]);

  //  fetch department logo api
  useEffect(() => {
    dispatch(
      getDepartmentLogo((data) => {
        setDepartment(data);
      })
    );
  }, []);
  //  fetch catagories collection 1st column
  const fetchCatagories = (item) => {
    setDepartmentId(item.genderId);
    setCollectionId(item.collectionId);
    dispatch(
      getDepartmentCatagories((data) => {
        setDepartmentCollections(data);
      }, item.genderId)
    );
  };
  //  logic to color focused department
  function selectDepartment(element) {
    element.focus();

    // element.style.borderBottom = '2px solid';
  }

  return {
    department,
    setDepartment,
    departmentCollections,
    setDepartmentCollections,
    departmentId,
    setDepartmentId,
    collectionId,
    setCollectionId,
    fetchCatagories,
    selectDepartment,
    showMegaMenu,
    hideMegaMenu,
    moreOptions,
    setMoreOptions,
    selectedElement,
    setSelectedElement,
  };
};
