import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useFilterOptions() {
  //react router search params state
  const [searchParams, setSearchParams] = useSearchParams();

  //state to manage apply filter modal
  const [showApplyFiltersModal, setShowApplyFiltersModal] = useState(false);

  //state to manage filter count

  const filterCount = searchParams.get('filtersCount');

  return {
    showApplyFiltersModal,
    setShowApplyFiltersModal,
    filterCount,
  };
}
