import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WarningIcon from 'assets/icons/warning-icon';
import SuccessIcon from 'assets/icons/success-icon';
import InformationIcon from 'assets/icons/information-icon';
import { useSanitizeHTML } from 'common-hooks/sanitize-html';
import { infoModalStyles as styles } from './styles';
import { closeInfoModal } from './actions';

const InfoModal = () => {
  const dispatch = useDispatch();

  const { open, messageTitle, message, type } = useSelector(
    ({ infoModalReducer }) => infoModalReducer
  );
  const { cleanHTML: cleanMessage } = useSanitizeHTML(message);

  const handleClose = () => {
    dispatch(closeInfoModal());
  };

  return (
    <Modal open={open} onClose={handleClose} sx={styles.modal}>
      <Box sx={styles.container}>
        <Stack alignItems='center' spacing={2}>
          {type === 'success' ? <SuccessIcon /> : null}
          {type === 'error' ? <WarningIcon /> : null}
          {type === 'warning' ? <WarningIcon fill='#ed6c02' /> : null}
          {type === 'info' ? <InformationIcon fill='#0288d1' /> : null}
          <Stack spacing={1}>
            <Typography variant='h1' sx={styles.title}>
              {messageTitle}
            </Typography>
            <Typography
              variant='body1'
              sx={styles.message}
              dangerouslySetInnerHTML={{ __html: cleanMessage }}
            >
              {/*{message}*/}
            </Typography>
          </Stack>
          <Button variant='contained' onClick={() => handleClose()}>
            Okay
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

InfoModal.propTypes = {};
InfoModal.defaultProps = {};

export default InfoModal;
