import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { QueryClient } from 'react-query';
import {
  setCustomerDomain,
  CUSTOMER_DETAIL_REQUEST,
  getCustomerDetails,
  checkTokenRequest,
  CHECK_RESET_TOEKN_REQUEST,
  enablePasswordChange,
  verifyTokenRequest,
  VERIFY_TOKEN_REQUEST,
  setQueryClient,
} from './actions';
import {
  selectCustomerDetailSelector,
  selectSubDomainSelector,
  actionLoaderSelector,
} from 'redux/selectors';
import { getSubDomain } from 'utils/helper';
import {
  MTATONCE_URL,
  INITIAL_PATHNAME,
  INITIAL_SEARCH,
  CHANGE_PASSWORD_REQUEST,
} from 'constants/index';
import { PrivateScreen } from 'routes/screen-names';
import LocalStorageManager from 'utils/local-storage-manager';
import { removeUrlParam } from 'utils/helper';
import contrast from 'utils/checkContrast';

export const useApp = () => {
  const dispatch = useDispatch();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const customerDetails = useSelector(selectCustomerDetailSelector);
  const subDomain = useSelector(selectSubDomainSelector);
  const customerDetailLoading = useSelector(
    actionLoaderSelector(CUSTOMER_DETAIL_REQUEST)
  );
  const isCheckTokenLoading = useSelector(
    actionLoaderSelector(CHECK_RESET_TOEKN_REQUEST)
  );
  const isVerifyTokenLoading = useSelector(
    actionLoaderSelector(VERIFY_TOKEN_REQUEST)
  );

  // react query client
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: true,
        refetchOnReconnect: true,
        retry: true,
      },
    },
  });

  useEffect(() => {
    dispatch(setQueryClient(queryClient));
  }, []);

  const checkUrlLink = async () => {
    if (!isCheckTokenLoading) {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('usr_token');
      const type = url.searchParams.get('redirectUrlType');
      const resetForgotToken = url.searchParams.get('usrchg_rqst');

      if (token || resetForgotToken) {
        const { status, isChangePassword } = await dispatch(
          checkTokenRequest({ token: token ? token : resetForgotToken, type })
        );
        if (status) {
          if (isChangePassword) {
            LocalStorageManager.setLocalStorage(
              CHANGE_PASSWORD_REQUEST,
              isChangePassword
            );
          }
          window.localStorage.href = PrivateScreen.home;
        }
      }
    }
  };

  const checkAdminLoginRequest = async () => {
    if (!isVerifyTokenLoading) {
      const token = new URL(window.location.href).searchParams.get('token');
      if (token) {
        const { status } = await dispatch(verifyTokenRequest(token));
        if (status) {
          window.location.href = PrivateScreen.home;
        }
      }
    }
  };

  const updateFavIcon = () => {
    if (customerDetails?.media?.favicon) {
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = customerDetails?.media?.favicon;
    }
  };

  const saveInitialUrl = () => {
    const removedToken = removeUrlParam('token', window.location.href);
    const removedUsrToken = removeUrlParam('usr_token', removedToken);
    const removedRedirectUrlType = removeUrlParam(
      'redirectUrlType',
      removedUsrToken
    );
    const removedUsrchgRqst = removeUrlParam(
      'usrchg_rqst',
      removedRedirectUrlType
    );
    const { pathname, search } = new URL(removedUsrchgRqst);

    if (pathname != '/' && pathname != '' && pathname != '/login') {
      LocalStorageManager.setLocalStorage(INITIAL_PATHNAME, pathname);
    }
    if (search != '') {
      LocalStorageManager.setLocalStorage(INITIAL_SEARCH, search);
    }
  };

  useEffect(() => {
    const fetchCustomerDetail = async () => {
      await dispatch(getCustomerDetails());
      saveInitialUrl();
      checkAdminLoginRequest();
      checkUrlLink();
    };
    if (subDomain) {
      fetchCustomerDetail();
      return;
    }
    dispatch(setCustomerDomain(getSubDomain()));
  }, [dispatch, subDomain]);

  useEffect(() => {
    if (customerDetails) {
      if (!customerDetails.active) {
        window.open(MTATONCE_URL, '_self');
      }
      document.title = customerDetails.name; // set title as subdomain
      updateFavIcon(); // update favicon
    }
  }, [customerDetails]);

  //set font color with higher contrast
  useEffect(() => {
    let themeColor = customerDetails?.primary_color;

    let contrastWithWhite = contrast(themeColor, '#ffffff');
    let contrastWithBlack = contrast(themeColor, '#000000');

    let preferredValue = '#fff';

    if (contrastWithBlack > contrastWithWhite) preferredValue = '#000000';
    else preferredValue = '#ffffff';

    customerDetails?.button_text_color
      ? document.documentElement.style.setProperty(
          '--contained-primary-button-text-color',
          customerDetails?.button_text_color
        )
      : document.documentElement.style.setProperty(
          '--contained-primary-button-text-color',
          preferredValue
        );
  }, [customerDetails]);

  return {
    customerDetailLoading,
    isAppLoading,
    setIsAppLoading,
    customerDetails,
    isVerifyTokenLoading,
    isCheckTokenLoading,
    queryClient,
  };
};
