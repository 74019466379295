import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Splash from './splash';
import { useAuth } from '../auth-hooks';
import { authPageStyles as styles } from './styles';
import { responsiveBreakpoints } from 'utils/utils';
import { loginStyles } from '../login/styles';

const AuthPage = ({
  children,
  showWelcomeMessage1,
  showWelcomeMessage2,
  showBackToLogin,
  showRegisterAccount,
  childrenStyle,
  height,
  positionAbsolute,
}) => {
  const {
    customerDetails,
    customerDetailsLoading,
    handleBackToLogInClick,
    handleRequestBtnClick,
  } = useAuth();
  const { theme, mobile, tablet } = responsiveBreakpoints();

  return (
    <Grid
      container
      overflow={mobile || tablet ? 'scroll' : 'hidden'}
      sx={{ height: '100vh', width: '100vw' }}
    >
      <Grid item xs={12} sm={12} md={6} sx={{ minHeight: '25%' }}>
        <Splash splashImage={customerDetails?.media?.splash_image} />
      </Grid>
      <Grid
        id='LoginForm-container'
        item
        xs={12}
        sm={12}
        md={6}
        sx={styles.container(theme, mobile, tablet)}
        // height={height}
      >
        {/* back to login stack */}
        <Stack
          direction={mobile ? 'column' : 'row'}
          spacing={mobile ? 1 : 0}
          justifyContent={mobile ? '' : 'space-between'}
        >
          <Stack>
            {showBackToLogin && (
              <Button
                variant='text'
                onClick={handleBackToLogInClick}
                startIcon={<ArrowBackIosNewIcon />}
                sx={{ width: 'fit-content', paddingLeft: '0px' }}
              >
                Back to Log In
              </Button>
            )}{' '}
          </Stack>
          {customerDetails.new_account_text_button && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent={mobile ? 'space-between' : 'right'}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '8px',
                columnGap: '16px',
              }}
            >
              {showRegisterAccount ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: '1',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant='body1'
                      sx={styles.wholesaleAccountText}
                    >
                      Don&apos;t have wholesale account?
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexGrow: '1',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant='outlined'
                      size={mobile ? 'small' : 'large'}
                      onClick={handleRequestBtnClick}
                      sx={styles.signupBtn}
                    >
                      {customerDetails.new_account_text_button}
                    </Button>
                  </Box>
                </>
              ) : (
                ' '
              )}
            </Stack>
          )}
        </Stack>
        {/* Logo and form children stack */}
        <Stack
          sx={{
            position: 'relative',
            flexGrow: 1,
            [theme.breakpoints.up('md')]: {
              paddingTop: '45px',
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: '10px',
            },
          }}
        >
          <Grid
            container
            sx={{
              width: '100%',
              paddingBottom: mobile ? '0px' : '51px',
            }}
          >
            <Grid
              item
              sx={{
                width: '100%',
                padding: '5px 0px 5px 0px',
              }}
            >
              <Stack
                justifyContent='center'
                alignItems='center'
                sx={{ width: '100%', height: '151px' }}
              >
                {customerDetails.media.logo ? (
                  <img
                    src={customerDetails.media.logo}
                    alt=''
                    style={mobile ? loginStyles.mobileLogo : loginStyles.logo}
                  />
                ) : (
                  <Skeleton
                    variant='rounded'
                    width='300px'
                    height='110px'
                    sx={loginStyles.logoSkeleton}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
          <Stack sx={childrenStyle}>{children}</Stack>

          {customerDetails.name ? (
            <Box sx={styles.welcomeMessageContainer}>
              {showWelcomeMessage1 && (
                <Typography sx={styles.welcomeMessage}>
                  Welcome to the <b>{customerDetails.name} Wholesale Website</b>
                  .
                </Typography>
              )}
              {showWelcomeMessage2 && (
                <Typography sx={styles.welcomeMessage}>
                  You must have an established account, username and password to
                  access this area.
                </Typography>
              )}
            </Box>
          ) : (
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={styles.welcomeMessageSkeletonContainer}
            >
              <Skeleton
                variant='text'
                width='200px'
                sx={styles.welcomeMessageSkeletonText}
              />
              <Skeleton
                variant='text'
                width='480px'
                sx={styles.welcomeMessageSkeletonText}
              />
            </Stack>
          )}
          {/* support number position absolute */}
          <Grid
            container
            sx={{
              position: 'absolute',
              bottom: '-2%',
              left: '-8px',
              width: '100%',
              // transform: 'translateX(-50%)',
            }}
          >
            <Grid item xs={12} sm={6} md={8} lg={8} sx={{ width: '100%' }}>
              {!customerDetailsLoading ? (
                customerDetails.email || customerDetails.phone ? (
                  <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                    justifyContent={mobile ? 'center' : 'left'}
                    sx={{
                      width: '100%',
                      padding: '4px 0px 0px 0px',
                    }}
                  >
                    <Typography sx={styles.forSupport}>
                      For Support:{' '}
                      {customerDetails.phone && (
                        <a
                          href={`tel:${customerDetails.phone}`}
                          style={styles.phone}
                        >
                          {`${customerDetails.phone} `}
                        </a>
                      )}
                      {customerDetails.email && customerDetails.phone && 'or '}
                      {customerDetails.email && (
                        <a
                          href={`mailto:${customerDetails.email}`}
                          style={styles.email}
                        >
                          {customerDetails.email}
                        </a>
                      )}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack></Stack>
                )
              ) : (
                <Skeleton variant='text' width='200px' sx={styles.fontSize12} />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              {!customerDetailsLoading ? (
                customerDetails.name &&
                customerDetails.current_year && (
                  <Stack
                    direction='row'
                    spacing={2}
                    alignItems='center'
                    justifyContent={mobile ? 'left' : 'right'}
                    sx={{
                      width: '100%',
                      padding: '5px 0px 0px 0px',
                    }}
                  >
                    <Typography
                      sx={
                        mobile
                          ? {
                              fontSize: '12px',
                              textAlign: 'center',
                              width: '100%',
                            }
                          : { ...styles.fontSize12 }
                      }
                    >
                      &copy;
                      <a
                        href='https://myatonce.com'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        MyAtOnce
                      </a>{' '}
                      {customerDetails.current_year} |{' '}
                      <a
                        href={customerDetails.website}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {customerDetails.name}
                      </a>
                    </Typography>
                  </Stack>
                )
              ) : (
                <Skeleton variant='text' width='200px' sx={styles.fontSize12} />
              )}
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

AuthPage.propTypes = {
  children: PropTypes.node.isRequired,
  showWelcomeMessage1: PropTypes.bool,
  showWelcomeMessage2: PropTypes.bool,
  showBackToLogin: PropTypes.bool,
  showRegisterAccount: PropTypes.bool,
  height: PropTypes.string,
  childrenStyle: PropTypes.object,
  positionAbsolute: PropTypes.bool,
};

AuthPage.defaultProps = {
  showWelcomeMessage1: false,
  showWelcomeMessage2: false,
  showBackToLogin: false,
  showRegisterAccount: true,
  height: 'auto',
  childrenStyle: {},
  positionAbsolute: false,
};

export default AuthPage;
