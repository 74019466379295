import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import HamburgerIconM from 'assets/icons/icons-for-mobile/hamburger-icon-m';
import SearchIcon from 'assets/icons/search-icon';
import Search from './search/search';
import DepartmentMenuMobile from './department-mobile/department-m';
import CancelIcon from 'assets/icons/cancel-icon';

import { useHeader2, useHeader2M } from './hooks';

const Header2M = () => {
  const { config } = useHeader2();
  const {
    showSearchUI,
    setShowSearchUI,
    showDepartmentMobileMenu,
    setShowDepartmentMobileMenu,
  } = useHeader2M();
  const navigate = useNavigate();
  const isMarketplace = useMatch(`/marketplace/*`);
  return (
    <AppBar
      position='static'
      sx={[
        {
          // position: 'sticky',
          // top: '31px',
          // top: `${
          //   !isEmpty(welcomeBarConfig) && welcomeBarConfig.wel_bar_pos == 1
          //     ? `${parseInt(welcomeBarConfig.bar_height) + 31}px`
          //     : '31px'
          // }`,
          height: 'fit-content',
          backgroundColor: 'var(--paper-background-color)',
          boxShadow: 0,
          justifyContent: 'center',
          padding: '0 21px 0 21px',
          borderBottom: '1px solid var(--secondary-border-color)',
          // zIndex: zIndex.stickyH2,
        },
        isMarketplace
          ? { borderBottom: '1px solid var(--secondary-border-color)' }
          : null,
      ]}
    >
      <Toolbar
        disableGutters
        sx={{ justifyContent: 'space-between', height: '100%' }}
      >
        <Stack direction='row' spacing={2} height='100%' alignItems='center'>
          <IconButton
            onClick={() => {
              setShowDepartmentMobileMenu(true);
            }}
          >
            <HamburgerIconM />
          </IconButton>

          <Tooltip
            title={config?.logo_alt_text}
            placement='right-end'
            sx={{
              backgroundColor: 'red',
              color: 'red',
            }}
          >
            <a
              onClick={() => {
                navigate('/');
                location.reload();
              }}
              style={{ cursor: 'pointer' }}
            >
              {!isEmpty(config) && (
                <img
                  src={config.media.logo}
                  alt='logo'
                  style={{ maxWidth: '300px', maxHeight: '50px' }}
                />
              )}
            </a>
          </Tooltip>
        </Stack>
        <Stack direction='row' height='100%' alignItems='center'>
          <IconButton
            onClick={() => {
              setShowSearchUI(!showSearchUI);
            }}
          >
            {showSearchUI ? (
              <CancelIcon height='22px' />
            ) : (
              <SearchIcon fill='#4d4663' />
            )}
          </IconButton>
        </Stack>
      </Toolbar>
      {showSearchUI ? (
        <Stack sx={{ padding: '10px 0px' }}>
          <Search />
        </Stack>
      ) : null}
      ,
      <DepartmentMenuMobile
        open={showDepartmentMobileMenu}
        setOpen={setShowDepartmentMobileMenu}
      />
    </AppBar>
  );
};

export default Header2M;
