import { INFO_MODAL_CLEAR, INFO_MODAL_SHOW } from './actions';

const initialState = {
  open: false,
  messageTitle: '',
  message: '',
  type: 'error',
};

const infoModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case INFO_MODAL_SHOW:
      return {
        ...state,
        open: true,
        messageTitle: action.payload.messageTitle,
        message: action.payload.message,
        type: action.payload.type,
      };
    case INFO_MODAL_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default infoModalReducer;
