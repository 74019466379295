import { React } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMatch } from 'react-router-dom';

import { responsiveBreakpoints } from 'utils/utils';
import TextField from 'components/text-field';
import ApplyFiltersModal from 'components/apply-filters-modal/apply-filters-modal';
import AlphaSort from 'components/modals/alphasort';
import FilterIcon from 'assets/icons/filter-icon';
import SearchIconVariant from 'assets/icons/search-icon-variant';
import useFilterOptions from './filter-options-hooks';

export default function FilterOptions({
  setSearchValue,
  searchValue,
  applyFilters,
  alphabetValue,
  setAlphabetValue,
  setRetailerPage,
  buyer,
  clearFilters,
  showAlphaSort,
}) {
  const { showApplyFiltersModal, setShowApplyFiltersModal, filterCount } =
    useFilterOptions();

  const { mobile } = responsiveBreakpoints();

  return (
    <Stack spacing={1}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        {showApplyFiltersModal ? (
          <ApplyFiltersModal
            open={showApplyFiltersModal}
            setOpen={setShowApplyFiltersModal}
            filtersToEnable={{
              salesRep: true,
              priceGroup: true,
              paymentTerms: buyer ? false : true,
              shippingMethod: buyer ? false : true,
              sortBy: buyer ? false : true,
              showRetailersAndBuyersWith: buyer ? false : true,
              groups: buyer ? false : true,
              currency: buyer ? true : false,
              showBuyers: buyer ? true : true,
            }}
            applyFilters={applyFilters}
          />
        ) : null}

        <Stack direction='row' gap={1} alignItems='center'>
          <TextField
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              if (alphabetValue !== '' && alphabetValue !== undefined) {
                setAlphabetValue('');
              }
            }}
            placeholder='Search'
            startAdornment={<SearchIconVariant />}
            sx={{
              width: 'auto',
              paddingLeft: '10px',
              minWidth: mobile ? 180 : 240,
            }}
          />
          {clearFilters ? (
            <Button
              variant='contained'
              onClick={clearFilters}
              color='error'
              size='small'
              fontStyle='bold'
              fontWeight='700'
              sx={{ cursor: 'pointer', textWrap: 'nowrap' }}
            >
              Clear Search Filters
            </Button>
          ) : null}
        </Stack>
        <Button
          size='small'
          variant='outlined'
          color={filterCount > 0 ? 'primary' : 'secondary'}
          startIcon={<FilterIcon />}
          onClick={(e) => {
            e.preventDefault();
            setShowApplyFiltersModal(!showApplyFiltersModal);
          }}
          sx={[
            { height: 'fit-content', padding: '7px 9px' },
            filterCount > 0 ? { backgroundColor: '#fff' } : null,
          ]}
        >
          <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
            <Typography>Filters</Typography>
            {filterCount > 0 ? (
              <Box
                sx={{
                  minWidth: '20px',
                  aspectRatio: '1/1',
                  padding: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'primary.main',
                  color: 'var(--contained-primary-button-text-color)',
                  fontSize: '12px',
                  lineHeight: '12px',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant='body1'
                  sx={{
                    color: 'var(--contained-primary-button-text-color)',
                    fontSize: '12px',
                    lineHeight: '12px',
                  }}
                >
                  {filterCount}
                </Typography>
              </Box>
            ) : null}
          </Stack>
        </Button>
      </Stack>
      {showAlphaSort ? (
        <AlphaSort
          alphabetValue={alphabetValue}
          setAlphabetValue={(val) => {
            if (val !== '') setSearchValue('');
            if (alphabetValue !== val) setRetailerPage(1);
            setAlphabetValue(val);
          }}
        />
      ) : null}
    </Stack>
  );
}

FilterOptions.propTypes = {
  variant: PropTypes.string,
  setSearchValue: PropTypes.func,
  searchValue: PropTypes.string,
  salesRep: PropTypes.array.isRequired,
  setSalesRep: PropTypes.func.isRequired,
  priceGroup: PropTypes.array.isRequired,
  setPriceGroup: PropTypes.func.isRequired,
  paymentTerms: PropTypes.array.isRequired,
  setPaymentTerms: PropTypes.func.isRequired,
  shippingMethod: PropTypes.array.isRequired,
  setShippingMethod: PropTypes.func.isRequired,
  sortBy: PropTypes.object.isRequired,
  setSortBy: PropTypes.func.isRequired,
  showRetailersAndBuyersWith: PropTypes.object.isRequired,
  setShowRetailersAndBuyersWith: PropTypes.func.isRequired,
  currency: PropTypes.array.isRequired,
  setCurrency: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  setGroups: PropTypes.func.isRequired,
  showBuyers: PropTypes.object.isRequired,
  setShowBuyers: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  alphabetValue: PropTypes.string.isRequired,
  setAlphabetValue: PropTypes.func.isRequired,
  getRetailers: PropTypes.func.isRequired,
  retailerPage: PropTypes.string.isRequired,
  setRetailerPage: PropTypes.func.isRequired,
  buyer: PropTypes.bool,
  clearFilters: PropTypes.func,
  searchParams: PropTypes.object.isRequired,
  showAlphaSort: PropTypes.bool,
};
FilterOptions.defaultProps = {
  variant: null,
  setSearchValue: null,
  searchValue: null,
  buyer: false,
  setRetailerPage: () => {},
  showAlphaSort: false,
};
