import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PublicScreen, PrivateScreen } from 'routes/screen-names';
import {
  selectCustomerDetailSelector,
  actionLoaderSelector,
} from 'redux/selectors';
import { CUSTOMER_DETAIL_REQUEST } from 'redux/actions';
import { isAuthenticated } from 'utils/auth';

export const useAuth = () => {
  const navigate = useNavigate();
  const customerDetails = useSelector(selectCustomerDetailSelector);
  const customerDetailsLoading = useSelector(
    actionLoaderSelector(CUSTOMER_DETAIL_REQUEST)
  );
  const pathName = window.location.pathname;

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(PrivateScreen.home);
    }
  }, []);

  const handleRequestBtnClick = () => {
    navigate(PublicScreen.register);
  };

  const handleBackToLogInClick = () => {
    navigate(PublicScreen.login);
  };

  const handleForgotUsernameAndPswdClick = () => {
    navigate(PublicScreen.Resetpassword);
  };

  return {
    customerDetails,
    pathName,
    customerDetailsLoading,
    handleRequestBtnClick,
    handleBackToLogInClick,
    handleForgotUsernameAndPswdClick,
  };
};
