import { React } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Delete from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlusSignIcon from '../../../assets/icons/plus-sign-icon';
import SuggestionsQuickPanelIcon from '../../../assets/icons/suggestions-quick-panel-icon';
import CreateNewSuggestionModal from 'components/modals/create-suggestion/create-new-suggestion-modal';
import useSuggestionsQuickPanel from './suggestions-quick-panel-hooks';
import useSuggestions from 'pages/suggestions/suggestions-hooks';
import Confirm from 'components/modals/confirmation-modal';
import AlertModal from 'components/modals/alert/alert-modal';
import EmptyState from 'components/empty-state';
import BuyerEmptyStateIcon from 'assets/icons/buyer-empty-state-icon';
import { zIndex } from 'constants/zIndex';

export default function SuggestionsQuickPanel({
  mouseOver,
  mouseOut,
  setOpen,
  open,
}) {
  const {
    suggestions,
    deleteSuggestion,
    createSuggestion,
    setActiveSuggestion,
    bulkDeleteSuggestions,
    setSelectAllChecked,
  } = useSuggestions();

  const {
    showNewSuggestionModal,
    setShowNewSuggestionModal,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    selectedId,
    setSelectedId,
    navigate,
    isSelected,
    toggleSelected,
    selectedSuggestions,
    setSelectedSuggestions,
    showBulkDeleteConfirm,
    setShowBulkDeleteConfirm,
    showBulkDeleteAlert,
    setShowBulkDeleteAlert,
    selectAllChecked,
  } = useSuggestionsQuickPanel(suggestions);

  const confirmDelete = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        deleteSuggestion(selectedId, setOpen);
        setShowDeleteConfirmation(false);
        if (window.location.pathname === `/suggestions/${selectedId}`) {
          navigate(`/`);
        }
      }}
    >
      Yes, Delete
    </Button>
  );

  const confirmBulkDelete = (
    <Button
      variant='contained'
      onClick={(e) => {
        e.preventDefault();
        bulkDeleteSuggestions(selectedSuggestions, setOpen);
        setShowBulkDeleteConfirm(false);
      }}
    >
      Yes, Delete
    </Button>
  );

  return (
    <Fade in={open} timeout={700}>
      <Paper
        elevation={3}
        sx={{
          width: '460px',
          position: 'absolute',
          top: '46px',
          right: 0,
          borderRadius: '6px',
          height: '570px',
          zIndex: zIndex.popover,
          overflow: 'hidden',
          border: '1px solid var(--secondary-border-color)',
          backgroundColor: 'var(--paper-background-color)',
        }}
        onMouseOver={mouseOver}
        onMouseOut={mouseOut}
      >
        {/* create suggestion */}
        <CreateNewSuggestionModal
          open={showNewSuggestionModal}
          setOpen={setShowNewSuggestionModal}
          createSuggestion={createSuggestion}
          quickPanelHandleClose={setOpen}
        />

        {/* delete confirmation */}
        {showDeleteConfirmation ? (
          <Confirm
            open={showDeleteConfirmation}
            setOpen={setShowDeleteConfirmation}
            title='Are you sure?'
            message='Are you sure you want to delete this suggestion.'
            action={confirmDelete}
          />
        ) : null}

        {/* bulk delete confirmation */}
        {showBulkDeleteConfirm ? (
          <Confirm
            open={showBulkDeleteConfirm}
            setOpen={setShowBulkDeleteConfirm}
            title='Are you sure?'
            message='Are you sure you want to delete selected suggestions.'
            action={confirmBulkDelete}
          />
        ) : null}

        {/* bulk delete alert */}
        <AlertModal
          open={showBulkDeleteAlert}
          setOpen={setShowBulkDeleteAlert}
          title='Please select at least one checkbox to delete selected suggestions'
          type='warning'
        />
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            borderBottom: '1px solid var(--secondary-border-color)',
            padding: '0 20px 0 20px',
            backgroundColor: 'var(--modal-header-color)',
          }}
        >
          <Typography
            variant='body2'
            sx={{ fontSize: '14px', fontWeight: 700 }}
          >
            Suggestions
          </Typography>
          <Stack direction='row' spacing={1}>
            <Button
              variant='text'
              startIcon={
                <PlusSignIcon
                  fill='#0066c0'
                  style={{ width: '15px', height: '15px' }}
                />
              }
              sx={{ fontSize: '12px', color: '#0066c0' }}
              onClick={(e) => {
                e.preventDefault();
                setShowNewSuggestionModal(true);
              }}
            >
              New Suggestion
            </Button>
            <FormControlLabel
              label='Select All'
              labelPlacement='start'
              control={
                <Checkbox
                  checked={selectAllChecked}
                  sx={{ marginLeft: '5px', width: '18px', height: '18px' }}
                  onClick={(e) => {
                    if (e.target.checked) {
                      var arr = [];
                      suggestions.data.forEach((suggestion) => {
                        arr.push(suggestion.id);
                      });
                      setSelectedSuggestions(arr);
                    } else {
                      setSelectedSuggestions([]);
                    }
                  }}
                />
              }
            />
          </Stack>
        </Stack>
        {suggestions ? (
          suggestions?.data?.length > 0 ? (
            <Stack sx={{ overflow: 'scroll', maxHeight: '500px' }}>
              {suggestions.data.map((item, i) => (
                <Grid
                  key={i}
                  container
                  sx={[
                    {
                      padding: '0 20px 5px 20px',
                      borderBottom: '1px solid var(--secondary-border-color)',
                    },
                    {
                      '&:hover': {
                        backgroundColor: '#f4f5f7',
                        cursor: 'pointer',
                      },
                    },
                    item.active === 'true'
                      ? {
                          borderLeft: '3px solid',
                          borderColor: 'primary.main',
                          borderBottom:
                            '1px solid var(--secondary-border-color)',
                        }
                      : {},
                  ]}
                >
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '7px',
                    }}
                  >
                    <SuggestionsQuickPanelIcon
                      active={item.active === 'true'}
                      fill='rgba(0, 0, 0, 0.6)'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    sm={9}
                    style={{
                      padding: '7px 0 4px 14px',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveSuggestion(item.id);
                      navigate(`/suggestions/${item.id}`);
                    }}
                  >
                    <Typography
                      variant='body2'
                      textAlign='left'
                      noWrap
                      sx={[
                        item.active === 'true'
                          ? { color: 'primary.main' }
                          : { color: 'var(--primary-font-color)' },
                        {
                          fontSize: '13px',
                          fontWeight: 700,
                        },
                      ]}
                    >
                      {item.name}
                    </Typography>

                    <Stack
                      direction='row'
                      spacing={2}
                      sx={{
                        padding: '5px 0 1px 0',
                      }}
                    >
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'rgba(77, 70, 99, 0.7)',
                        }}
                      >
                        ITEMS:{' '}
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'var(--primary-font-color)',
                          }}
                        >
                          {item.products}
                        </span>
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        style={{
                          color: 'rgba(77, 70, 99, 0.7)',
                        }}
                      >
                        DELIVERIES:{' '}
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'var(--primary-font-color)',
                          }}
                        >
                          {item.deliveries}
                        </span>
                      </Typography>
                    </Stack>
                    <Typography
                      textAlign='left'
                      sx={{
                        fontSize: '12px',
                        color: 'rgba(77, 70, 99, 0.7)',
                      }}
                    >
                      <AccessTimeIcon
                        sx={{
                          width: '12px',
                          height: '12px',
                          position: 'relative',
                          top: '2px',
                        }}
                      />{' '}
                      Created on {item.created}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedId(item.id);
                        setShowDeleteConfirmation(true);
                      }}
                    >
                      <Delete
                        fontSize='small'
                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Checkbox
                      checked={isSelected(item.id)}
                      sx={{ width: '18px', height: '18px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleSelected(item.id);
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Stack>
          ) : (
            <Box
              sx={{
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EmptyState
                title='No suggestions created yet'
                subTitle=''
                icon={<BuyerEmptyStateIcon />}
              />
            </Box>
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Stack
          sx={{
            borderTop: '1px solid rgba(205, 204, 214, 0.7)',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: '0 10px',
            backgroundColor: 'var(--modal-header-color)',
          }}
          direction='row'
          justifyContent='space-between'
        >
          <Button
            variant='text'
            sx={{
              fontSize: '12px',
              color: '#0066c0',
              fontWeight: '700',
            }}
            onClick={(e) => {
              e.preventDefault();
              navigate('/suggestions');
            }}
          >
            View All Suggestions
          </Button>
          <Button
            variant='text'
            sx={{
              color: 'var(--error-color)',
              fontSize: '12px',
              fontWeight: '700',
            }}
            onClick={(e) => {
              e.preventDefault();
              if (selectedSuggestions.length === 0) {
                setShowBulkDeleteAlert(true);
              } else {
                setShowBulkDeleteConfirm(true);
              }
            }}
          >
            Delete Suggestions
          </Button>
        </Stack>
      </Paper>
    </Fade>
  );
}

SuggestionsQuickPanel.propTypes = {
  mouseOver: PropTypes.func.isRequired,
  mouseOut: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
