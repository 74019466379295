import { useState, useRef } from 'react';
import { useMutation } from 'react-query';
import { api } from 'api';
import { useSelector } from 'react-redux';
import { loggedInUserSelector } from 'redux/selectors';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants/index';
import { useDispatch } from 'react-redux';
export default function useAddContacts() {
  const [selectedItems, setSelectedItems] = useState([]);
  const formikRef = useRef();
  const [emailData, setEmailData] = useState([]);

  const user = useSelector(loggedInUserSelector);
  const dispatch = useDispatch();

  const onSubmitFormikHandler = (values) => {
    let payload = {
      email: values.email,
    };
    addContacts.mutate(payload);
  };
  // Add Contacts
  const addContacts = useMutation({
    mutationFn: (payload) => {
      return api.post('order/addContacts', payload);
    },
    onSuccess: (data) => {
      if (data.data.status) {
        setEmailData((prev) => {
          return [
            {
              id: data?.data?.data?.id,
              contact_email: data?.data?.data?.contact_email,
            },
            ...prev,
          ];
        });
        setSelectedItems((prev) => {
          return [
            ...prev,
            {
              id: data?.data?.data?.id,
              contact_email: data?.data?.data?.contact_email,
            },
          ];
        });
      } else {
        dispatch(
          showSnackbar({
            snackbarMessage: data?.data?.message ?? 'Somthing went wrong',
            type: snackbarTypes.ERROR,
          })
        );
      }

      // getPdfDataQuery.refetch();
    },
  });

  // ======================> Remove Email ID <=========================================
  const deleteEmailQuery = useMutation({
    mutationFn: () => {
      return api.delete('order/removeContacts', {
        data: {
          ids: (() => {
            let updatedArray = [];
            selectedItems.forEach((item) => {
              updatedArray.push(parseInt(item.id));
            });
            return updatedArray;
          })(),
        },
      });
    },
    onSuccess: () => {
      setSelectedItems([]);
    },
  });

  return {
    selectedItems,
    setSelectedItems,
    onSubmitFormikHandler,
    emailData,
    formikRef,
    deleteEmailQuery,
    user,
  };
}
