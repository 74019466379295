import React from 'react';
import PropTypes from 'prop-types';

export default function FilterIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.156'
      height='15.5'
      viewBox='0 0 15.156 15.5'
      fill={fill}
      style={sx}
    >
      <path
        stroke={fill}
        strokeWidth='0.5px'
        d='M14.5 0H1.126a.628.628 0 0 0-.56.341A.637.637 0 0 0 .615 1l4.9 6.9.005.007a1.4 1.4 0 0 1 .275.831v5.632a.626.626 0 0 0 .625.63.644.644 0 0 0 .247-.049L9.42 13.9a.6.6 0 0 0 .41-.588V8.743a1.4 1.4 0 0 1 .275-.831l.005-.007L15.01 1a.636.636 0 0 0 .05-.663A.628.628 0 0 0 14.5 0zM9.491 7.462a2.168 2.168 0 0 0-.423 1.282v4.476l-2.513.958V8.743a2.167 2.167 0 0 0-.423-1.282L1.377.761h12.871zm0 0'
        transform='translate(-.235 .25)'
      />
    </svg>
  );
}

FilterIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

FilterIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
