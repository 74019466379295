import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api/index';
import { showSnackbar } from '../../components/snackbar/actions';
import { snackbarTypes } from '../../constants';
import { ADD_NOTES } from '../customers/actions';

const namespace = 'pages/catalog/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const PRODUCT_LIST_REQUEST = `${namespace}PRODUCT_LIST_REQUEST`;
export const PRODUCT_CATALOG_FILTERS = `${namespace}PRODUCT_CATALOG_FILTERS`;
export const ADD_GRID_PRODUCT_DRAFT = `${namespace}ADD_GRID_PRODUCT_DRAFT`;
export const REMOVE_GRID_PRODUCT_DRAFT = `${namespace}REMOVE_GRID_PRODUCT_DRAFT`;
export const ADD_REMOVE_SINGLE_PRODUCT_SUGGESTION = `${namespace}ADD_REMOVE_SINGLE_PRODUCT_SUGGESTION`;
export const GET_CATALOG_SORT_OPTIONS = `${namespace}GET_CATALOG_SORT_OPTIONS`;
export const UPDATE_PRODUCT_SORT = `${namespace}UPDATE_CATALOG_SORT`;
export const UPDATE_WAREHOUSE_SORT = `${namespace}UPDATE_WAREHOUSE_SORT`;
export const UPDATE_PRICE_SORT = `${namespace}UPDATE_PRICE_SORT`;
export const UPDATE_SUGGEST_ALL = `${namespace}UPDATE_SUGGEST_ALL`;
export const ADD_ORDER_ENTRY_PRODUCT_DRAFT = `${namespace}ADD_ORDER_ENTRY_PRODUCT_DRAFT`;
export const UPDATE_PRODUCT_QUANTITY_REQUEST = `${namespace}UPDATE_PRODUCT_QUANTITY_REQUEST`;
export const CREATE_PRODUCT_PDF_REQUEST = `${namespace}CREATE_PRODUCT_PDF_REQUEST`;
export const GET_CATALOG_LINK_NAME_REQUEST = `${namespace}GET_CATALOG_LINK_NAME_REQUEST`;
export const PRODUCT_DETAILS_BY_ID = `${namespace}PRODUCT_DETAILS_BY_ID`;
export const SET_LEFT_NAV_COLLECTION = `${namespace}SET_LEFT_NAV_COLLECTION`;
export const PRODUCT_LISTING_REQUEST = `${namespace}PRODUCT_LISTING_REQUEST`;
export const SET_PRODUCT_VIEW_STYLE = `${namespace}SET_PRODUCT_VIEW_STYLE`;
export const PRODUCT_LEFT_NAV_REQUEST = `${namespace}PRODUCT_LEFT_NAV_REQUEST`;
export const SET_VISIBLE_COLLECTION_INFO = `${namespace}SET_VISIBLE_COLLECTION_INFO`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const setLeftNavCollection = (payload) => ({
  type: SET_LEFT_NAV_COLLECTION,
  payload,
});

export const setProductViewStyle = (payload) => ({
  type: SET_PRODUCT_VIEW_STYLE,
  payload,
});

export const setVisibleCollectionInfo = (payload) => ({
  type: SET_VISIBLE_COLLECTION_INFO,
  payload,
});

export const fetchProductLeftNav = (queryParams) => async (dispatch) => {
  try {
    dispatch(addActionLoader(PRODUCT_LEFT_NAV_REQUEST));
    const response = await api.get(apiEndPoints.productLeftNavBar(queryParams));
    const { data = {}, status = false } = response.data;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(PRODUCT_LEFT_NAV_REQUEST));
  }
};

export const fetchProductFilters = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(PRODUCT_CATALOG_FILTERS));
    const url = `${apiEndPoints.getCatalogFilters}?${payload}`;
    const response = await api.get(url);
    const { data } = response;
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(PRODUCT_CATALOG_FILTERS));
  }
};

export const addGridItemToActiveDraft = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_GRID_PRODUCT_DRAFT));
    const url = `${apiEndPoints.addGridItemToDraft}?worksheet_collection_id=${payload.worksheetId}&item_id=${payload.productId}&shop_collection_id=${payload.collectionId}`;
    const response = await api.get(url);
    const { data, message } = response;
    if (data) {
      return data;
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_GRID_PRODUCT_DRAFT));
  }
};

export const removeGridItemToActiveDraft = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(REMOVE_GRID_PRODUCT_DRAFT));
    const url = apiEndPoints.removeDraftItemGrid;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return data;
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(REMOVE_GRID_PRODUCT_DRAFT));
  }
};

export const updateItemSingleSuggestion = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_REMOVE_SINGLE_PRODUCT_SUGGESTION));
    const url = `${apiEndPoints.singleItemSuggest}?productId=${payload.productId}&lineSheetId=${payload.lineSheetId}&isSuggest=${payload.isSuggest}`;
    const response = await api.get(url);
    const { data, message } = response;
    if (data) {
      return data;
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_REMOVE_SINGLE_PRODUCT_SUGGESTION));
  }
};

export const getSortOptionsCatalog = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_CATALOG_SORT_OPTIONS));
    const response = await api.get(apiEndPoints.getSortOptionsCatalog);
    const { data, message } = response;
    if (data) {
      return data;
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_CATALOG_SORT_OPTIONS));
  }
};

export const updatePriceSorting = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_PRICE_SORT));
    const url = apiEndPoints.updateSortOptionsPrice;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_PRICE_SORT));
  }
};

export const updateProductSorting = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_PRODUCT_SORT));
    const url = apiEndPoints.updateSortOptionsCatalog;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_PRODUCT_SORT));
  }
};

export const updateWarehouseSorting = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_WAREHOUSE_SORT));
    const url = apiEndPoints.updateSortOptionsWarehouse;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_WAREHOUSE_SORT));
  }
};

export const suggestAllCallForCatalog = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_SUGGEST_ALL));
    const qs = require('qs');
    const queryPrams = `?${qs.stringify(payload)}`;
    const url = apiEndPoints.suggestAll + queryPrams;
    const response = await api.get(url);
    const { data, message } = response;
    if (data) {
      return data;
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_SUGGEST_ALL));
  }
};

export const addProductAndQuantityToDraft = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_ORDER_ENTRY_PRODUCT_DRAFT));
    const url = apiEndPoints.addItemToDraft;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_ORDER_ENTRY_PRODUCT_DRAFT));
  }
};

export const updateProductQuantityDraft = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_PRODUCT_QUANTITY_REQUEST));
    const url = apiEndPoints.setProductQuantity;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_PRODUCT_QUANTITY_REQUEST));
  }
};

export const createProductPdfRequest = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CREATE_PRODUCT_PDF_REQUEST));
    const url = apiEndPoints.createProductPdf;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CREATE_PRODUCT_PDF_REQUEST));
  }
};

export const getProductCategoryStatsRequest =
  (queryPrams) => async (dispatch) => {
    try {
      // dispatch(addActionLoader(CREATE_PRODUCT_PDF_REQUEST));
      const url = apiEndPoints.loadStats + queryPrams;
      const response = await api.get(url);
      const { data, message } = response;
      if (data) {
        return { data, message };
      } else {
        dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
      }
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      // dispatch(removeActionLoader(CREATE_PRODUCT_PDF_REQUEST));
    }
  };

export const getProductPdfOptionsRequest = () => async (dispatch) => {
  try {
    const url = apiEndPoints.pdfOptions;
    const response = await api.get(url);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  }
};

export const getCatalogLinkNameRequest = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_CATALOG_LINK_NAME_REQUEST));
    const url = apiEndPoints.getCatalogLinkName;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_CATALOG_LINK_NAME_REQUEST));
  }
};

export const updateProductQuantityModalCall = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_PRODUCT_QUANTITY_REQUEST));
    const url = apiEndPoints.updateDraftItem;
    const response = await api.post(url, payload);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_PRODUCT_QUANTITY_REQUEST));
  }
};

export const fetchProductDetailsById = (id, wsId) => async (dispatch) => {
  try {
    dispatch(addActionLoader(PRODUCT_DETAILS_BY_ID));
    const url = apiEndPoints.productDetailsById(id, wsId);
    const response = await api.get(url);
    const { data, message } = response;
    if (data) {
      return { data, message };
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(PRODUCT_DETAILS_BY_ID));
  }
};

export const updateUserSitePrefs = (payload) => async (dispatch) => {
  try {
    const url = apiEndPoints.updateUserSitePrefs;
    const response = await api.post(url, payload);
    const { data: { message = '', status = false } = {} } = response;
    if (status) {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'success' }));
      return true;
    } else {
      dispatch(showSnackbar({ snackbarMessage: message, type: 'error' }));
      return false;
    }
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  }
};

export const getProductList = (queryParams) => async (dispatch) => {
  try {
    dispatch(addActionLoader(PRODUCT_LISTING_REQUEST));
    const { data: { data = {}, status = false } = {} } = await api.get(
      apiEndPoints.productListing(queryParams)
    );
    if (status) {
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage:
          'Something went wrong, please try again after sometime.',
        type: 'error',
      })
    );
    return status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(PRODUCT_LISTING_REQUEST));
  }
};

export const copyUrlToClipboard = (url) => async (dispatch) => {
  try {
    await navigator.clipboard.writeText(url);
    dispatch(
      showSnackbar({
        snackbarMessage: 'URL successfully copied to clipboard!',
        type: 'success',
      })
    );
  } catch (error) {
    dispatch(
      showSnackbar({
        snackbarMessage: 'Failed to copy URL to clipboard.',
        type: 'error',
      })
    );
    return false;
  }
};

export const sendRequestInfoEmailFunc = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_NOTES));
    const response = await api.post(apiEndPoints.sendRequestInfoEmail, payload);
    const { data, data: { status = false, message = '' } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return data;
    }
    response?.data?.errors?.forEach((mess) => {
      Object.keys(mess).forEach((key) => {
        dispatch(
          showSnackbar({
            snackbarMessage: mess[key],
            type: snackbarTypes.ERROR,
          })
        );
      });
    });
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_NOTES));
  }
};
