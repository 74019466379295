import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Textarea({
  id,
  label,
  value,
  onChange,
  rows,
  placeholder,
  error,
  helperText,
  sx,
  disabled,
  required,
}) {
  return (
    <Box style={sx}>
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <textarea
        id={id}
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        style={{
          width: '100%',
          outline: 'none',
          border: error
            ? '1px solid var(--error-color)'
            : '1px solid var(--primary-border-color)',
          borderRadius: '4px',
          resize: 'none',
          padding: '8px 15px 10px 15px',
          fontFamily: 'Avenir',
          fontSize: '15px',
          fontWeight: 500,
          color: 'var(--primary-font-color)',
        }}
      />
      {error ? (
        <Typography
          variant='body2'
          sx={{
            color: 'var(--error-color)',
            marginBottom: '16px',
          }}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
  );
}

Textarea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  rows: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Textarea.defaultProps = {
  id: null,
  placeholder: '',
  sx: {},
  error: false,
  helperText: null,
  disabled: false,
  required: false,
};
