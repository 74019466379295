import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customerSupportDataSelector } from 'redux/selectors';
import {
  addRetailerBillingAddress,
  addRetailerShippingAddress,
  //   addPaymentType,
  editRetailerBillingAddress,
  editRetailerShippingAddress,
} from 'redux/actions/retailer-actions';
import { NOTIFICATION_TYPE } from 'constants/index';
import {
  declineRetailerRequest,
  getMoreNotificationsData,
  approveNewBuyerRequest,
  checkBuyerUserName,
  declineBuyerActionRequest,
  getMoreEditNotificationsData,
  approveEditBuyerInfoRequest,
} from './actions';

export const useBuyerRequests = (
  callback,
  selectedNotification,
  closeNotification,
  refreshPage
) => {
  const dispatch = useDispatch({});
  const [isNewRequest, setIsNewRequest] = useState(false);
  const [isNewBuyerRequest, setIsNewBuyerRequest] = useState(false);
  const [isNewBillingRequest, setIsNewBillingRequest] = useState(false);
  const [isNewShippingRequest, setIsNewShippingRequest] = useState(false);
  const [isBuyerInfoEditRequest, setIsBuyerInfoEditRequest] = useState(false);
  const [isShippingEditRequest, setIsShippingEditRequest] = useState(false);
  const [isBillingEditRequest, setIsBillingEditRequest] = useState(false);
  const [selectedNotificaitonMoreInfo, setSelectedNotificationMroeInfo] =
    useState();

  const { country = [], payment_method = [] } = useSelector(
    customerSupportDataSelector
  );

  useEffect(() => {
    getNotificationMoreInfo(selectedNotification);
  }, [selectedNotification, refreshPage]);

  // get more details about selected notification and perform action
  const getNotificationMoreInfo = async (notification) => {
    if (!notification) return;
    const { reference_type_id, id } = notification;
    if (!id) return;
    let data;
    if (
      reference_type_id !=
        NOTIFICATION_TYPE.BUYER_EDIT_SHIPPING_ADDRESS_REQUEST &&
      reference_type_id !=
        NOTIFICATION_TYPE.BUYER_EDIT_BILLING_ADDRESS_REQUEST &&
      reference_type_id != NOTIFICATION_TYPE.BUYER_EDIT_USER_PROFILE_REQUEST &&
      reference_type_id != NOTIFICATION_TYPE.BUYER_EDIT_RETAILER_PROFILE_REQUEST
    ) {
      data = await dispatch(getMoreNotificationsData(id));
    } else {
      data = await dispatch(getMoreEditNotificationsData(id));
    }

    if (data) {
      setSelectedNotificationMroeInfo(data);
      switch (reference_type_id) {
        case NOTIFICATION_TYPE.BUYER_EDIT_SHIIPING:
          break;
        case NOTIFICATION_TYPE.BUYER_EDIT_BILLING:
          break;
        case NOTIFICATION_TYPE.NEW_ACCOUNT_CREATION:
          setIsNewRequest(true);
          break;
        case NOTIFICATION_TYPE.NEW_BUYER_FROM_ANOTHER_BUYER:
          setIsNewBuyerRequest(true);
          break;
        case NOTIFICATION_TYPE.BUYER_NEW_BILLING_ADDRESS_REQUEST:
          setIsNewBillingRequest(true);
          break;
        case NOTIFICATION_TYPE.BUYER_NEW_SHIPPING_ADDRESS_REQUEST:
          setIsNewShippingRequest(true);
          break;
        // case NOTIFICATION_TYPE.BUYER_NEW_PAYMENT_TYPE_REQUEST:
        //   setIsNewPaymentRequest(true);
        //   break;
        case NOTIFICATION_TYPE.BUYER_EDIT_SHIPPING_ADDRESS_REQUEST:
          setIsShippingEditRequest(true);
          break;
        case NOTIFICATION_TYPE.BUYER_EDIT_BILLING_ADDRESS_REQUEST:
          setIsBillingEditRequest(true);
          break;
        case NOTIFICATION_TYPE.BUYER_EDIT_USER_PROFILE_REQUEST:
          setIsBuyerInfoEditRequest(true);
          break;
        case NOTIFICATION_TYPE.BUYER_EDIT_RETAILER_PROFILE_REQUEST:
          setIsBuyerInfoEditRequest(true);
          break;
        default:
          break;
      }
      closeNotification();
    }
  };

  // decline a new retailer request
  const handleDeclineNewRetailerRequest = async (retailerId) => {
    await dispatch(
      declineRetailerRequest(retailerId, {
        notificationId: selectedNotification.id,
      })
    );
  };

  // handle approve new buyer request
  const handleApproveNewBuyerRequest = async (data) => {
    let updatedPayload = { ...data, notificationId: selectedNotification?.id };
    const isApproved = await dispatch(approveNewBuyerRequest(updatedPayload));
    if (isApproved) {
      setIsNewBuyerRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  // get formatted country list
  const getCountryList = () => {
    return country.map((country) => {
      return {
        label: country.name,
        value: country.code,
        isDefault: false,
      };
    });
  };

  const getMethodList = () => {
    return payment_method.map((method) => {
      return {
        label: method.name,
        value: method.payment_code,
        isDefault: method.default,
      };
    });
  };

  // checks if buyer username is available
  const handleCheckBuyerUserName = async (username, setFieldError) => {
    if (username !== '') {
      const data = await dispatch(checkBuyerUserName(username));
      if (data) {
        setFieldError('username', data.message);
      }
    }
  };

  // decline a buyer request
  const declineBuyerRequest = async () => {
    const declined = await dispatch(
      declineBuyerActionRequest(selectedNotification?.id)
    );
    if (declined) {
      setIsNewBuyerRequest(false);
      setIsNewBillingRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  // approve new billing request
  const handleApproveNewBillingRequest = async (payload) => {
    const updatedPayload = {
      ...payload,
      notificationId: selectedNotification?.id,
    };
    let response = await dispatch(addRetailerBillingAddress(updatedPayload));

    if (response) {
      setIsNewBillingRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  // approve new shipping request
  const handleApproveNewShippingRequest = async (payload) => {
    const updatedPayload = {
      ...payload,
      notificationId: selectedNotification?.id,
    };
    let response = await dispatch(addRetailerShippingAddress(updatedPayload));

    if (response) {
      setIsNewShippingRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  // const handleApproveNewPaymentRequest = async (payload) => {
  //   const updatedPayload = {
  //     ...payload,
  //     notificationId: selectedNotification?.id,
  //   };
  //   let response = await dispatch(addPaymentType(updatedPayload));

  //   if (response) {
  //     setIsNewPaymentRequest(false);
  //     setSelectedNotificationMroeInfo();
  //     setSelectedNotification();
  //   }
  // };

  // approve edit shippinh request
  const handleApproveEditShippingRequest = async (payload) => {
    const updatedPayload = {
      ...payload,
      notificationId: selectedNotification?.id,
    };
    let response = await dispatch(
      editRetailerShippingAddress(
        updatedPayload,
        selectedNotificaitonMoreInfo?.id
      )
    );

    if (response) {
      setIsShippingEditRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  // approve edit billing request
  const handleApproveEditBillingRequest = async (payload) => {
    const updatedPayload = {
      ...payload,
      notificationId: selectedNotification?.id,
    };
    let response = await dispatch(
      editRetailerBillingAddress(
        updatedPayload,
        selectedNotificaitonMoreInfo?.id
      )
    );

    if (response) {
      setIsBillingEditRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  // handle approve buyer info request
  const handleApproveEditBuyerInfoRequest = async (payload) => {
    const updatedPayload = {
      ...payload,
      notificationId: selectedNotification?.id,
    };
    let response = await dispatch(approveEditBuyerInfoRequest(updatedPayload));

    if (response) {
      setIsBuyerInfoEditRequest(false);
      setSelectedNotificationMroeInfo();
      callback();
    }
  };

  return {
    isNewRequest,
    setIsNewRequest,
    selectedNotification,
    handleDeclineNewRetailerRequest,
    isNewBuyerRequest,
    setIsNewBuyerRequest,
    selectedNotificaitonMoreInfo,
    handleApproveNewBuyerRequest,
    getCountryList,
    handleCheckBuyerUserName,
    declineBuyerRequest,
    isNewBillingRequest,
    setIsNewBillingRequest,
    handleApproveNewBillingRequest,
    isNewShippingRequest,
    setIsNewShippingRequest,
    handleApproveNewShippingRequest,
    getMethodList,
    isShippingEditRequest,
    isBillingEditRequest,
    setIsBillingEditRequest,
    setIsShippingEditRequest,
    handleApproveEditShippingRequest,
    handleApproveEditBillingRequest,
    isBuyerInfoEditRequest,
    setIsBuyerInfoEditRequest,
    handleApproveEditBuyerInfoRequest,
  };
};
