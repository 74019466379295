import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useCopySalesTeam from './copy-sales-team-hook';
import TextField from 'components/text-field';
import SearchIconVariant from 'assets/icons/search-icon-variant';

export default function CopySalesTeam({
  selectedAssociates,
  setSelectedAssociates,
  sx,
}) {
  const [isSelectAll, setIsSelectAll] = useState(false);

  function handleCheck(value) {
    if (selectedAssociates.some((obj) => obj.name === value.name)) {
      setSelectedAssociates(() =>
        selectedAssociates.filter((item) => item.name !== value.name)
      );
    } else {
      setSelectedAssociates((prev) => {
        return [...prev, value];
      });
    }
  }
  useEffect(() => {
    if (salesReps?.displayAssosciateTitle) {
      if (
        salesReps?.displayAssosciateTitleData?.length ==
        selectedAssociates?.length
      ) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
    } else {
      if (salesReps?.sortedData?.length == selectedAssociates?.length) {
        setIsSelectAll(true);
      } else {
        setIsSelectAll(false);
      }
    }
  }, [selectedAssociates]);

  const { salesReps, searchValue, setSearchValue } = useCopySalesTeam();
  // dummy data ends
  return (
    <Box>
      <TableContainer sx={{ ...sx }}>
        <Table size='small' sx={{}}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Stack direction='row' spacing={1}>
                  <Checkbox
                    checked={isSelectAll}
                    onClick={(e) => {
                      if (isSelectAll) {
                        setSelectedAssociates([]);
                      } else {
                        if (salesReps?.displayAssosciateTitle) {
                          // salesReps.displayAssosciateTitleData.forEach(
                          //   (data) => {
                          //     data.data.forEach((buyer) => {
                          //       handleCheck(buyer);
                          //     });
                          //   }
                          // );
                          setSelectedAssociates(
                            salesReps?.displayAssosciateTitleData
                          );
                        } else {
                          // salesReps.sortedData?.forEach((buyer) => {
                          //   handleCheck(buyer);
                          // });
                          setSelectedAssociates(salesReps?.sortedData);
                        }
                      }
                      setIsSelectAll(!isSelectAll);
                    }}
                  />
                  <span>ASSOCIATE NAME</span>
                </Stack>
              </TableCell>
              <TableCell>EMAIL ADDRESS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ border: 'none' }} colSpan={2}>
                <TextField
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    let searchQuery = e.target.value;
                    searchQuery = salesReps?.displayAssosciateTitleData.flatMap(
                      (section) =>
                        section.data.filter((item) => {
                          if (searchQuery !== '') {
                            if (
                              item.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()) ||
                              item.email
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            ) {
                              return item;
                            }
                          } else {
                            return item;
                          }
                        })
                    );
                  }}
                  placeholder='Search'
                  startAdornment={<SearchIconVariant />}
                  sx={{
                    width: '100%',
                    margin: 0,
                    borderRadius: '0 4px 4px 0',
                    paddingLeft: '10px',
                    fontSize: '11px',
                    height: '30px',
                  }}
                />
              </TableCell>
            </TableRow>
            {salesReps?.displayAssosciateTitle
              ? salesReps?.displayAssosciateTitleData
                  .map((section) => ({
                    ...section,
                    data: section.data.filter(
                      (item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchValue.toLowerCase()) ||
                        item.email
                          .toLowerCase()
                          .includes(searchValue.toLowerCase())
                    ),
                  }))
                  ?.map((data, i) =>
                    data?.data?.map((row, j) => {
                      if (j == 0) {
                        return (
                          <>
                            <TableRow key={j} sx={{ border: 'none' }}>
                              {' '}
                              <TableCell
                                sx={{ fontWeight: '900px', border: 'none' }}
                              >
                                <Stack direction='column' spacing={2}>
                                  {j == 0 ? (
                                    <Typography
                                      variant='h2'
                                      sx={{
                                        fontColor: '#6A6A6A',
                                        opacity: '0.6',
                                      }}
                                    >
                                      {data.title}
                                    </Typography>
                                  ) : null}
                                </Stack>
                              </TableCell>
                            </TableRow>
                            <TableRow key={j} sx={{ borderTop: 'none' }}>
                              <TableCell
                                sx={{ fontWeight: '900px', border: 'none' }}
                              >
                                <Stack
                                  spacing={1}
                                  direction='row'
                                  alignItems='center'
                                >
                                  <Checkbox
                                    checked={(() => {
                                      var x = false;
                                      selectedAssociates.forEach((item) => {
                                        if (item.name === row.name) x = true;
                                      });
                                      return x;
                                    })()}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleCheck(row);
                                    }}
                                  />
                                  <Typography>{row.name}</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{ border: 'none' }}>
                                <Typography> {row.email}</Typography>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      } else {
                        return (
                          <TableRow key={j}>
                            <TableCell sx={{ fontWeight: '900px' }}>
                              <Stack
                                spacing={1}
                                direction='row'
                                alignItems='center'
                              >
                                <Checkbox
                                  checked={(() => {
                                    var x = false;
                                    selectedAssociates.forEach((item) => {
                                      if (item.name === row.name) x = true;
                                    });
                                    return x;
                                  })()}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleCheck(row);
                                  }}
                                />
                                <Typography>{row.name}</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography> {row.email}</Typography>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })
                  )
              : salesReps?.sortedData
                  ?.filter(
                    (item) =>
                      item.name
                        .toLowerCase()
                        .includes(searchValue.toLowerCase()) ||
                      item.email
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                  )
                  .map((row, i) => (
                    <TableRow key={i}>
                      {/* <TableCell sx={{ fontWeight: 900 }}>{row.accname}</TableCell> */}
                      <TableCell sx={{ fontWeight: '900px' }}>
                        <Stack spacing={1} direction='row' alignItems='center'>
                          <Checkbox
                            checked={(() => {
                              var x = false;
                              selectedAssociates.forEach((item) => {
                                if (item.name === row.name) x = true;
                              });
                              return x;
                            })()}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheck(row);
                            }}
                          />
                          <Typography>{row.name}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination table ends */}
    </Box>
  );
}
CopySalesTeam.propTypes = {
  selectedAssociates: PropTypes.array.isRequired,
  setSelectedAssociates: PropTypes.func.isRequired,
  sx: PropTypes.object.isRequired,
};
CopySalesTeam.defaultProps = {
  sx: {},
};
