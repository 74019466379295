import {
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_SUBDOMAIN,
  SET_CLIENT_OPTIONS,
  SET_QUERY_CLIENT,
  SET_WELCOMEBAR_DATA,
} from './actions';

const initialState = {
  subDomain: '',
  customerDetail: null,
  showChangePassword: false,
  clientOptions: {},
  queryClient: {},
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_DETAILS:
      return { ...state, customerDetail: action.payload };
    case SET_CUSTOMER_SUBDOMAIN:
      return { ...state, subDomain: action.payload };
    case SET_CLIENT_OPTIONS:
      return { ...state, clientOptions: action.payload };
    case SET_QUERY_CLIENT:
      return { ...state, queryClient: action.payload };
    case SET_WELCOMEBAR_DATA:
      return { ...state, welcomeBarData: action.payload };
    default:
      return state;
  }
};

export default appReducer;
