import React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment/moment';

import NotificationIcon from 'assets/icons/notification-icon';
import { useNotifications } from './notifications-hooks';
import NotificationSkeleton from './notification-skeleton';
import { notificationStyles as styles } from './styles';
import BuyerRequests from 'components/buyer-requests/buyer-requests';
import RingingBell from '../../../components/ringing-bell/RingingBell';
import { undefinedCheck } from '../../../utils/helper';
import EmailStatusModal from 'components/modals/email-status-modal/email-status-modal';
import EmailIcon from 'assets/icons/email-icon';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Notifications = () => {
  const {
    notificationAnchorEl,
    openNotification,
    closeNotification,
    notifications,
    notificationsLoading,
    handleMarkAllAsReadClick,
    handleDismissAllClick,
    fetchNotifications,
    handleNotificationClick,
    selectedNotification,
    showDismissAll,
    showMarkAllAsRead,
    newNotificaitonAvailable,
    requestActionCallback,
    showEmailStatusModal,
    setShowEmailStatusModal,
    notificationId,
    setNotificationId,
  } = useNotifications({ setOpen: false });

  return (
    <>
      <Tooltip title='Notifications' arrow>
        <Box
          sx={{
            height: '31px',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <IconButton
            onClick={openNotification}
            title='Notifications'
            sx={{ opacity: 0.9 }}
          >
            {newNotificaitonAvailable ? (
              <RingingBell />
            ) : (
              <NotificationIcon
                fill='var(--color-white)'
                showWhiteDot={showMarkAllAsRead}
                showRedDot={newNotificaitonAvailable}
                active={Boolean(notificationAnchorEl)}
              />
            )}
          </IconButton>
        </Box>
      </Tooltip>
      <Popover
        disableScrollLock
        id='notification-popover'
        open={Boolean(notificationAnchorEl)}
        anchorEl={notificationAnchorEl}
        onClose={closeNotification}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{ onMouseLeave: closeNotification }}
      >
        <Stack sx={styles.container}>
          <Stack sx={styles.headerContainer}>
            <Typography variant='h2'>Notifications</Typography>
            {showDismissAll && (
              <Button
                variant='text'
                sx={{ color: '#0066c0' }}
                onClick={handleDismissAllClick}
              >
                Dismiss All
              </Button>
            )}
            {showMarkAllAsRead && (
              <Button
                variant='text'
                sx={{ color: '#0066c0' }}
                onClick={handleMarkAllAsReadClick}
              >
                Mark all as read
              </Button>
            )}
          </Stack>

          {notificationsLoading ? (
            <NotificationSkeleton />
          ) : (
            <Stack sx={{ flex: 1 }}>
              {notifications?.length > 0 ? (
                notifications.map((notification) => {
                  return (
                    <Stack
                      onClick={() => {
                        setNotificationId(notification?.id);
                        handleNotificationClick(notification);
                      }}
                      sx={[
                        styles.notificationCardContainer,
                        notification.read == 1 ? styles.notificaitonViewd : '',
                      ]}
                      key={notification.id}
                    >
                      {notification?.reference_type_id == '37' ? (
                        <IconButton
                          sx={{
                            backgroundColor: 'primary.main',
                            height: '38px',
                            width: '38px',
                            padding: '5px 5px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '50%',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                            },
                          }}
                        >
                          <MailOutlineIcon sx={{ color: 'white' }} />
                        </IconButton>
                      ) : (
                        <Avatar
                          alt=''
                          src={notification?.sender_profile_photo}
                          height='38px'
                          width='38px'
                        />
                      )}

                      <Stack flex={1}>
                        <Typography sx={styles.message}>
                          {notification?.message}
                        </Typography>
                        <Stack sx={styles.dateAndAction}>
                          <Stack direction='column' spacing={0.5}>
                            <Typography sx={styles.date}>
                              {moment(notification?.created_at).format(
                                'MMM Do, YYYY [at] hh:mm a'
                              )}
                            </Typography>
                            {notification?.reference_type_id == '37' ? (
                              <Typography
                                variant='body1'
                                sx={{
                                  color: '#0066C0',
                                  width: 'fit-content',
                                  padding: '0px',
                                  fontWeight: '700',
                                }}
                              >
                                View List
                              </Typography>
                            ) : null}
                          </Stack>

                          {notification?.status && (
                            <Button
                              variant={
                                notification?.status == 'Review Request' ||
                                (notification?.status == 'Approved' &&
                                  notification.is_action == 1)
                                  ? 'text'
                                  : 'outlined'
                              }
                              size='small'
                              color={
                                notification?.status == 'Declined'
                                  ? 'warning'
                                  : 'success'
                              }
                              sx={[
                                styles.action,
                                notification?.status == 'Review Request' && {
                                  color: '#0066c0',
                                },
                                notification?.status == 'Approved' &&
                                  notification.is_action == 1 && {
                                    color: '#0066c0',
                                  },
                              ]}
                            >
                              {notification?.status == 'Approved' &&
                              notification.is_action == 1
                                ? 'Review Request'
                                : notification?.status}
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  );
                })
              ) : (
                <Stack sx={styles.emptyContainer}>
                  <Stack sx={styles.emptyIcon}>
                    <NotificationIcon
                      fill='var(--primary-icon-color)'
                      active={false}
                      height='28px'
                      width='23px'
                    />
                  </Stack>
                  <Typography variant='h2' sx={{ marginBottom: '5px' }}>
                    No notifications yet
                  </Typography>
                  <Typography sx={styles.emptyMessage}>
                    When you get notifications, they’ll show up here
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={fetchNotifications}
                  >
                    Refresh
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      </Popover>
      {showEmailStatusModal ? (
        <EmailStatusModal
          open={showEmailStatusModal}
          setOpen={setShowEmailStatusModal}
          notificationId={notificationId}
        />
      ) : null}
      <BuyerRequests
        selectedNotification={undefinedCheck(selectedNotification, {})} // Added to remove warning from the console
        callback={requestActionCallback}
        closeNotification={closeNotification}
      />
    </>
  );
};

export default Notifications;
