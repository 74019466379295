import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useMatch } from 'react-router-dom';
import { api } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveDraftSuggestion } from '../../redux/actions/global-actions';
import { departmentListSelector } from 'redux/selectors';
import SessionStorageManager from 'utils/session-storage-manager';
import { useUpdateURLParams } from 'common-hooks/update-query-params-hooks';

export default function useDrafts() {
  const { updateURLParams } = useUpdateURLParams();
  const [selected, setSelected] = useState('');
  const dispatch = useDispatch();
  const [showCreateNewDraftModal, setShowCreateNewDraftModal] = useState(false);
  const [drafts, setDrafts] = useState(null);
  const [sharedDrafts, setSharedDrafts] = useState(null);

  //default department
  const defaultDepartment = useSelector(departmentListSelector);

  //checks if we're on catalog page
  const isCatalog = useMatch('/catalog');

  const navigate = useNavigate();
  const route = useLocation();
  useEffect(() => {
    if (route.pathname === '/shared-draft') {
      setSelected('SHARED_DRAFTS');
    }

    if (route.pathname === '/drafts') {
      setSelected('DRAFTS');
    }
  }, [route.pathname]);

  // get drafts
  async function getDrafts(quickpanel) {
    const res = await api.get(`/draftList`);

    if (!res.data.status) {
      setDrafts(res.data);
      if (res.data?.draftCount === 1) {
        SessionStorageManager.setSessionStorage('showSelectDraft', false);
      }

      if (quickpanel) {
        getSharedDrafts();
      }
    }
  }

  // get shared drafts
  async function getSharedDrafts() {
    const res = await api.get(`/sharedDraftList`);
    setSharedDrafts(res.data);
  }

  // create draft
  async function createDraft(name, setOpen, redirect) {
    const res = await api.post(`/draft`, {
      name: name,
      description: '',
      productGenderId: 0,
    });

    if (res.data.status) {
      const backToCatalogUrl =
        SessionStorageManager.getSessionStorage('last_catalog_url') ??
        `/catalog?gndr=${defaultDepartment?.commonGenderId}&cl=${defaultDepartment?.commonCollectionId}&start=0`;

      dispatch(getActiveDraftSuggestion());
      getDrafts();
      updateURLParams({ resetCatalog: true });
      SessionStorageManager.setSessionStorage('showSelectDraft', true);
      //close quick panel
      if (setOpen) setOpen(false);
      if (redirect && !isCatalog)
        navigate(`${backToCatalogUrl}&resetCatalog=true`);
    }
  }

  // delete draft
  async function deleteDraft(id, setOpen) {
    const res = await api.delete(`/deleteDraft/${id}`);

    if (res.data.status) {
      dispatch(getActiveDraftSuggestion());
      getDrafts();
      SessionStorageManager.setSessionStorage('showSelectDraft', true);
      //close quick panel
      setOpen(false);
      //if on /draft/{idOfDraftGettingDeleted}, then redirect to home page
      if (window.location.pathname === `/drafts/${id}`) {
        navigate('/');
      }
    }
  }

  // set default draft
  async function setDefaultDraft(id) {
    const res = await api.get(`/setDefaultDraft/${id}`);

    if (res?.data[0]?.status) {
      dispatch(getActiveDraftSuggestion());
      SessionStorageManager.setSessionStorage('showSelectDraft', false);
      getDrafts();
    }
  }

  // fetch
  useEffect(() => {
    if (selected === 'DRAFTS') {
      getDrafts();
      navigate('/drafts');
    }
    if (selected === 'SHARED_DRAFTS') {
      getSharedDrafts();
      navigate('/shared-draft');
    }
  }, [selected]);

  return {
    selected,
    setSelected,
    showCreateNewDraftModal,
    setShowCreateNewDraftModal,
    drafts,
    sharedDrafts,
    createDraft,
    deleteDraft,
    setDefaultDraft,
    getDrafts,
    getSharedDrafts,
  };
}
