import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInUserSelector, actionLoaderSelector } from 'redux/selectors';
import { changePassword } from '../actions';
import { CHANGE_PASSWORD_REQUEST } from '../constants';
import { CHANGE_PASSWORD_REQUEST as changePassowrdKey } from 'constants';
import LocalStorageManager from 'utils/local-storage-manager';

export const useChangePasswordHooks = () => {
  const dispatch = useDispatch();
  const [openPassword, setOpenPassword] = useState(false);
  const loggedInUserData = useSelector(loggedInUserSelector);
  const showChangePassword = JSON.parse(
    LocalStorageManager.getLocalStorage(changePassowrdKey)
  );
  const passwordChangeLoading = useSelector(
    actionLoaderSelector(CHANGE_PASSWORD_REQUEST)
  );

  useEffect(() => {
    setOpenPassword(
      !!showChangePassword || loggedInUserData?.isForceChangePassword
    );
  }, [showChangePassword, loggedInUserData]);

  const handleSavePasswordBtnClick = async (data) => {
    const payload = {
      password: data?.newpassword,
    };
    const isSuccess = await dispatch(changePassword(payload));
    if (isSuccess) {
      handleCloseChangePassword();
      setOpenPassword(false);
    }
  };

  const handleCloseChangePassword = () => {
    if (!passwordChangeLoading) {
      LocalStorageManager.removeLocalStorage(changePassowrdKey);
      setOpenPassword(false);
    }
  };

  return {
    showChangePassword,
    handleSavePasswordBtnClick,
    loggedInUserData,
    handleCloseChangePassword,
    passwordChangeLoading,
    openPassword,
  };
};
