import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPassword,
  recoverUsername,
  RESET_PASSWORD_REQUEST,
  FORGET_USERNAME_REQUEST,
} from '../actions';
import {
  actionLoaderSelector,
  selectCustomerDetailSelector,
} from 'redux/selectors';

export const useResetPassword = (handleRecoverMessage) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const customerDetails = useSelector(selectCustomerDetailSelector);
  const isResetPasswordLoading = useSelector(
    actionLoaderSelector(RESET_PASSWORD_REQUEST)
  );
  const isForgetPasswordLoading = useSelector(
    actionLoaderSelector(FORGET_USERNAME_REQUEST)
  );
  const [selectedValue, setSelectedValue] = useState('reset_password');
  const [apiErrorMessage, setErrorApiMessage] = useState();
  const [fieldsError, setFieldsError] = useState([]);
  const [linkedAccount, setLinkedAccount] = useState([]);

  useEffect(() => {
    setLinkedAccount([]);
    setFieldsError([]);
    setErrorApiMessage('');
    formikRef.current?.resetForm();
  }, [selectedValue]);

  const handleResetPasswordBtnClick = async (values) => {
    try {
      const {
        status,
        message,
        errors,
        accounts = [],
      } = await dispatch(resetPassword(values));

      if (status) {
        return handleRecoverMessage(message, status);
      } else {
        if (errors) {
          const message = errors.map((error) => {
            return Object.values(error);
          });
          setFieldsError(message);
        } else if (accounts.length > 0) {
          setErrorApiMessage(message);
          setLinkedAccount(accounts);
        } else {
          setErrorApiMessage(message);
        }
      }
    } catch (error) {
      // TODO: handle error
    }
  };

  const handleRecoverUsernameBtnClick = async (values) => {
    try {
      const {
        status,
        message,
        errors,
        accounts = [],
      } = await dispatch(recoverUsername(values));
      if (status) {
        return handleRecoverMessage(message, status);
      } else {
        if (errors) {
          const message = errors.map((error) => {
            return Object.values(error);
          });
          setFieldsError(message);
        } else if (accounts.length > 0) {
          setErrorApiMessage(message);
          setLinkedAccount(accounts);
        } else {
          setErrorApiMessage(message);
        }
      }
    } catch (error) {
      // TODO: handle error
    }
  };

  const handleRecoverOptionChange = (value) => {
    setSelectedValue(value);
  };

  const handleCloseError = () => {
    setFieldsError([]);
    setErrorApiMessage(null);
  };

  return {
    handleResetPasswordBtnClick,
    handleRecoverUsernameBtnClick,
    isResetPasswordLoading,
    isForgetPasswordLoading,
    customerDetails,
    selectedValue,
    handleRecoverOptionChange,
    apiErrorMessage,
    fieldsError,
    handleCloseError,
    linkedAccount,
    formikRef,
  };
};

export const useRecoverAccount = () => {
  const [showRecoverMessage, setShowRecoverMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const customerDetails = useSelector(selectCustomerDetailSelector);

  const handleRecoverMessage = (message, status) => {
    setResponseMessage(message);
    setShowRecoverMessage(status);
  };

  return {
    handleRecoverMessage,
    showRecoverMessage,
    customerDetails,
    responseMessage,
  };
};
