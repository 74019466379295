import {
  api,
  addActionLoader,
  removeActionLoader,
  apiEndPoints,
} from '../../api';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants/index';
import { setDepartmentListItems } from 'redux/actions/global-actions';

import {
  SET_API_ERROR,
  SET_HEADER1_MENU_OPTIONS,
  WELCOME_BAR_REQUEST,
  HEADER_MENU_REQUEST,
  SET_CUSTOMER_SUPPORT_DATA,
  CUSTOMER_SUPPORT_DATA_REQUEST,
  GET_DEPARTMENT_LOGO,
  GET_DEPARTMENT_CATAGORIES,
  GET_DEPARTMENT_DELIVERIES,
  GET_DEPARTMENT_CATAGORY,
  NOTIFICATION_REQUEST,
  NOTIFICATION_DISMISSALL_REQUEST,
  NOTIFICATION_VIEWALL_REQUEST,
  ADVANCE_FILTER_REQUEST,
  MARK_VIEWED_NOTIFICATION,
  CHECK_NEW_NOTIFICATION_EXIST,
  CHANGE_PASSWORD_REQUEST,
} from './constants';

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

const setHeader1MenuOptions = (payload) => ({
  type: SET_HEADER1_MENU_OPTIONS,
  payload,
});

const setCustomerSupportData = (payload) => ({
  type: SET_CUSTOMER_SUPPORT_DATA,
  payload,
});

export const getHeader1Menu = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(HEADER_MENU_REQUEST));
    const response = await api.get(apiEndPoints.headerMenu);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      dispatch(setHeader1MenuOptions(data.data));
      return status;
    }
    return status;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(HEADER_MENU_REQUEST));
  }
};

export const getCustomerSupportData = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(CUSTOMER_SUPPORT_DATA_REQUEST));
    const response = await api.get(apiEndPoints.customerSupportData);
    const { data, data: { status = false } = {} } = response;
    if (status && data) {
      dispatch(setCustomerSupportData(data?.data));
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CUSTOMER_SUPPORT_DATA_REQUEST));
  }
};

//  GET DEPARTMENT LOGO
export const getDepartmentLogo =
  (successCallBack, payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(GET_DEPARTMENT_LOGO));

      const response = await api.get(apiEndPoints.departments);
      const { data } = response;

      if (response.status === 200 || data.status) {
        dispatch(setDepartmentListItems(data.data));
        return successCallBack(data.data);
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_DEPARTMENT_LOGO));
    }
  };
//  GET DEPARTMENT COLLECTIONS
export const getDepartmentCatagories =
  (successCallBack, payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(GET_DEPARTMENT_CATAGORIES));

      const response = await api.get(apiEndPoints.departmentCatagory(payload));
      const { data } = response;

      if (response.status === 200 || data.status) {
        return successCallBack(data.data);
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_DEPARTMENT_CATAGORIES));
    }
  };
//  GET DEPARTMENT Deliveries
export const getDepartmentDeliveries =
  (successCallBack, payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(GET_DEPARTMENT_DELIVERIES));

      const response = await api.get(
        `${apiEndPoints.deliveries}?collectionName=${payload.CollectionName}&type=${payload.Type}&departmentId=${payload.DepartmentId}`
      );
      const { data } = response;

      if (response.status === 200 || data.status) {
        return successCallBack(data.data);
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_DEPARTMENT_DELIVERIES));
    }
  };
//  GET catagories
export const getDepartmentCatagory =
  (successCallBack, payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(GET_DEPARTMENT_CATAGORY));

      const response = await api.get(
        `${apiEndPoints.categories}?collectionId=${payload.collectionId}&departmentId=${payload.departmentId}`
      );
      const { data } = response;

      if (response.status === 200 || data.status) {
        return successCallBack(data.data);
      }
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_DEPARTMENT_CATAGORY));
    }
  };

export const getAdvanceSearchFilters = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADVANCE_FILTER_REQUEST));
    const response = await api.get(apiEndPoints.getAdvanceSearchFilters);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADVANCE_FILTER_REQUEST));
  }
};

export const getNotificationsData = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(NOTIFICATION_REQUEST));
    const response = await api.get(apiEndPoints.notifications);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(NOTIFICATION_REQUEST));
  }
};

export const viewdAllNotification = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(NOTIFICATION_VIEWALL_REQUEST));
    const response = await api.put(apiEndPoints.notificationViewdAll);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(NOTIFICATION_VIEWALL_REQUEST));
  }
};

export const dismissAllNotification = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(NOTIFICATION_DISMISSALL_REQUEST));
    const response = await api.put(apiEndPoints.notificationDismissAll);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(NOTIFICATION_DISMISSALL_REQUEST));
  }
};

export const markViewedNotification = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(MARK_VIEWED_NOTIFICATION));
    const response = await api.post(apiEndPoints.notificationViewed(id));
    const { data: { status = false } = {} } = response;
    if (status) {
      return status;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(MARK_VIEWED_NOTIFICATION));
  }
};

export const checkNewNotificationExist = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHECK_NEW_NOTIFICATION_EXIST));
    const response = await api.get(apiEndPoints.checkNewNotificationExists);
    const { data, data: { status = false } = {} } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHECK_NEW_NOTIFICATION_EXIST));
  }
};

export const changePassword = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CHANGE_PASSWORD_REQUEST));
    const response = await api.put(apiEndPoints.changePassword, payload);
    const { data: { status = false, message = '' } = {} } = response;
    if (status) {
      sessionStorage.removeItem('linkExpired');
      dispatch(
        showSnackbar({ snackbarMessage: message, type: snackbarTypes.SUCCESS })
      );
      return true;
    }
    dispatch(
      showSnackbar({ snackbarMessage: message, type: snackbarTypes.ERROR })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CHANGE_PASSWORD_REQUEST));
  }
};
