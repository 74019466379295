import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { customerSupportDataSelector } from 'redux/selectors';
import { useSearchParams } from 'react-router-dom';

// Default state for all filters
const defaultFilterState = {
  salesRep: [],
  priceGroup: [],
  paymentTerms: [],
  shippingMethod: [],
  groups: [],
  sortBy: '',
  showRetailersAndBuyersWith: '',
  showBuyers: [],
  currency: [],
};

export const useApplyFiltersModal = (applyFilters, setOpen) => {
  // Hook to get search parameters from the URL
  const [searchParams] = useSearchParams();

  // Retrieves support data from Redux store
  const supportData = useSelector(customerSupportDataSelector);

  // State to manage all filters collectively
  const [filters, setFilters] = useState(defaultFilterState);

  // Function to update individual filter state
  const setFilter = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  // Initializes form values from URL search params or defaults
  const initializeForm = () => {
    const filtersFromParams = searchParams.get('filters');
    const parsedFilters = filtersFromParams
      ? JSON.parse(filtersFromParams)
      : {};
    setFilters({ ...defaultFilterState, ...parsedFilters });
  };

  // Effect to initialize form when searchParams change
  useEffect(() => {
    initializeForm();
  }, [searchParams]);

  // Handles form submission for applying or resetting filters
  const handleSubmit = ({ action }) => {
    // Filters out empty values and constructs the applied filters object
    const appliedFilters = Object.entries(filters)
      .filter(([key, value]) =>
        Array.isArray(value) ? value.length > 0 : value
      )
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

    // Calculates the total count of applied filters
    const filtersCount = Object.values(appliedFilters).reduce(
      (count, value) => count + (Array.isArray(value) ? value.length : 1),
      0
    );

    // Applies or resets filters based on the action
    if (action === 'apply') {
      applyFilters(JSON.stringify(appliedFilters), filtersCount);
    } else if (action === 'reset') {
      applyFilters('', 0);
    }

    // Closes the modal after action
    setOpen(false);
  };

  // Returning filter state, setter function, submit handler, and support data
  return {
    ...filters,
    setFilter,
    handleSubmit,
    supportData,
  };
};
