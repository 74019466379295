import React from 'react';
import PropTypes from 'prop-types';

export default function AttachFileIcon({ fill, sx }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='14.903'
      height='14'
      viewBox='0 0 14.903 14'
      fill={fill}
      style={sx}
    >
      <g transform='translate(0 -10)'>
        <path
          d='M81.155,14.318l2-2v8.3a.677.677,0,1,0,1.355,0v-8.3l2,2a.677.677,0,0,0,.958-.958L84.317,10.2q-.024-.024-.05-.045l-.023-.017-.03-.022-.029-.017-.028-.017-.03-.014-.03-.014-.029-.011-.033-.012L84,10.022l-.035-.009-.034-.005L83.9,10l-.063,0h-.008l-.063,0-.031,0-.035.005-.034.008-.031.008-.032.011-.03.011-.029.014-.031.015-.027.016-.03.018-.028.021-.025.019c-.016.014-.032.028-.047.043l0,0L80.2,13.36a.677.677,0,0,0,.958.958Z'
          transform='translate(-76.386)'
        />
        <path
          d='M14.226,160a.677.677,0,0,0-.677.677v5.194H1.355v-5.194a.677.677,0,0,0-1.355,0v5.871a.677.677,0,0,0,.677.677H14.226a.677.677,0,0,0,.677-.677v-5.871A.677.677,0,0,0,14.226,160Z'
          transform='translate(0 -143.226)'
        />
      </g>
    </svg>
  );
}

AttachFileIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
};

AttachFileIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
};
